import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SidePanel from '../Admin-Dashbord/SidePanel';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import Dheader from '../Admin-Dashbord/header';

const Post_Blog = () => {
    const [postTitles, setPostTitles] = useState([]);   // for get data
    const [selectedPostid, setSelectedPost] = useState(''); // got category
    const [posttitle, setposttitle] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [content, setContent] = useState('');
    const [metatitle, setMetatitle] = useState('');
    const [metatags, setMetatags] = useState('');
    const [metadiscription, setMetadiscription] = useState('');
    const navigate = useNavigate();

    // fetch data from backend
    useEffect(() => {
        const apiUrl = 'https://www.sdmipl.com/portal/api/category-view';
        axios.get(apiUrl)
            .then((response) => {
                setPostTitles(response.data.records);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('title', posttitle);
        formData.append('category_id', selectedPostid);
        formData.append('image', selectedImage);
        formData.append('content', content);
        formData.append('meta_title', metatitle);
        formData.append('meta_keyword', metatags);
        formData.append('meta_description', metadiscription);

        const token = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
        };

        axios.post('https://www.sdmipl.com/portal/api/post-create', formData, { headers })
            .then((res) => {
                console.warn("post data", formData);
                if (res.status === 200) {
                    navigate('/postview');
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 4000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer);
                            toast.addEventListener('mouseleave', Swal.resumeTimer);
                        },
                    });
                    Toast.fire({
                        icon: 'success',
                        title: res.data.message,
                    });
                } else {
                    alert(res.data.message);
                }
            })
            .catch((error) => {
                console.error('Error submitting form:', error);
            });
    }

// for Meta Title
    const titleCount = metatitle.length;
let titlecolorClass = '';

if (titleCount === 0) {
    titlecolorClass = 'text-warning';
} else if (titleCount >= 1 && titleCount <= 69) {
    titlecolorClass = 'text-success';
} else if (titleCount === 70) {
    titlecolorClass = 'text-danger';
} 
// for Meta keyword 
const keywordCount = metatags.length;
    let keywordcolorClass = '';

    if (keywordCount === 0) {
        keywordcolorClass = 'text-danger';
    } else if (keywordCount < 10) {
        keywordcolorClass = 'text-warning';
    } else {
        keywordcolorClass = 'text-success';
    }
//   for Meta Discription
const DiscriptionCount = metadiscription.length;
let discriptioncolorClass = '';

if (DiscriptionCount === 0) {
    discriptioncolorClass = 'text-warning';
} else if (DiscriptionCount >= 1 && DiscriptionCount <= 159) {
    discriptioncolorClass = 'text-success';
} else if (DiscriptionCount === 160) {
    discriptioncolorClass = 'text-danger';
} 

    return (
        <>
            <div className='d-flex'>
                <SidePanel />
                <section className="right-dash">
                    <Dheader />
                    <div className="container col-lg-12">
                        <div className='card create'>
                        <h2 className='text-center'>Create Content / Post / Blog</h2>
                        <div className="form-group">
                            <form onSubmit={handleSubmit}>
                                <div className='form-group mt-2 text-center'>
                                    <label htmlFor="catagory-title" className='mr-3'>Category title :</label>
                                    <select className='text-center p-1 rounded' name="catagory-title" id="catagory-title" onChange={(e) => setSelectedPost(e.target.value)}>
                                        <option value="">Select a Category title</option>
                                        {postTitles && postTitles.map((item, index) => (
                                            <option  key={index} value={item.id}>
                                                {item.title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="post-title">Post Title :</label>
                                    <input type='text' className="form-control" id="post-title" name="post-title" placeholder="Enter your Post Title" onChange={(e) => setposttitle(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="Post-image">Post image:</label>
                                    <input type="file" className="form-control" id="image" name="image" onChange={handleImageChange} />
                                    {selectedImage && (
                                        <div>
                                            <p>Selected file: {selectedImage.name}</p>
                                            <img src={URL.createObjectURL(selectedImage)} alt="Selected-item" width="100" />
                                        </div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="Post-Content">Post Content:</label>
                                    <textarea className="form-control" id="Post-Content" name="Post-Content" placeholder="Enter your Post Content" onChange={(e) => setContent(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="meta-tags">Meta - Title:  </label>
                                    <textarea type="text" onChange={(e) => setMetatitle(e.target.value)} className="form-control" id="meta-title" name="meta-title" placeholder="Enter your Meta Tags"     />
                                    <span className={titlecolorClass}> {metatitle.length} maximum 70 Characters*</span>
                                
                                </div>
                                <div className="form-group">
                                <label htmlFor="meta_keyword"> Meta - Keyword: minimum 10 keywords or Above *</label>
                                    <textarea type="text" className="form-control" id="meta_keyword" name="meta-tags" placeholder="Enter your Meta Tags" onChange={(e) => setMetatags(e.target.value)} minLength={10}/>
                                    <span className={keywordcolorClass}>{metatags.length}  /  10 minimum  keywords or Above *</span>
                                   
                                </div>
                                <div className="form-group">
                                    <label htmlFor="meta-discription">Meta - Description </label>
                                    <textarea type="text" className="form-control" onChange={(e) => setMetadiscription(e.target.value)} id="meta-discription" name="meta-discription" placeholder="Enter your Meta  Description"   />
                                    <span className={discriptioncolorClass}>{metadiscription.length} / 160 maximum keywords or less *</span>
                                </div>
                                <div className='btn'>
                                    <input type="submit" className="btn btn-warning" value="Submit" />
                                </div>
                            </form>
                        </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Post_Blog;
