// =======================      { Sub_menu_options  }     ====================================

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import SidePanel from '../Admin-Dashbord/SidePanel';
import { useNavigate } from 'react-router-dom';
import Dheader from '../Admin-Dashbord/header';

const Sub_menu = () => {
    const [getdata, setgetdata] = useState(''); 
    const [getid, setgetid] = useState('');
    const [header, setHeader] = useState('');
    const navigate = useNavigate();

    const FormData = {
        menu_id: getid,
        title: header,

    };

    useEffect(() => {
        const apiUrl = 'https://www.sdmipl.com/portal/api/menu-view';
        axios.get(apiUrl)
            .then((response) => {
                setgetdata(response.data.records);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(FormData);
        axios.post('https://www.sdmipl.com/portal/api/submenu-create', FormData)
            .then((res) => {
                if (res.data.error === "true") {
                    alert(res.data.message);
                    navigate('/submenu-view');
                } else {
                    alert(res.data.message);
                }
            })
            .catch((error) => {
                console.error('Error submitting form:', error);
            });
    };

    return (
        <>
            <div className='d-flex'>
                <SidePanel />
                < section className="right-dash">
                    <Dheader />
                    <div className="container mt-3 col-lg-12">
                        <div className='row'>
                            <div className='card create'>
                                <h2 className='text-center'> Create Sub Menu  Details </h2>
                                <form onSubmit={handleSubmit}>
                                    <div className='form-group mt-5 text-center'>
                                        <label htmlFor="catagory-title" className='mr-3'>Menu title:</label>
                                        <select name="catagory-title" className='text-center p-1 rounded' id="catagory-title" onChange={(e) => setgetid(e.target.value)}>
                                            <option value="">Select a Menu title</option>
                                            {getdata && getdata.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.title}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="sub-header-menu-1">Sub Menu Title</label>
                                        <input type="text" className="form-control" id="sub-header-menu-1" onChange={(e) => setHeader(e.target.value)} name="sub-header-menu-1" placeholder="Enter your Category Title" />
                                    </div>
                                    <div className='btn'><input type="submit" className="btn btn-warning" value="Submit" /></div>
                                </form>
                            </div>
                        </div>

                    </div>
                </section>

            </div>

        </>

    )

}

export default Sub_menu;