import axios from 'axios';
import React from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
function Dheader() {
    // logout and Centerpanel 
    const navigate = useNavigate();

    // logout Functionality
    const handleLogout = () => {
        const token = localStorage.getItem('token');
        if (token) {
            axios.post('https://www.sdmipl.com/portal/api/logout', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((res) => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2500,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer);
                            toast.addEventListener('mouseleave', Swal.resumeTimer);
                        },
                    });
                    Toast.fire({
                        icon: 'success',
                        text: 'Logout',
                        title: 'Logout Successfully',
                    });
                    // alert(res.data.message);
                    localStorage.removeItem('token');
                    navigate('/login');
                })
                .catch((error) => {
                    console.error('Error logging out:', error);
                });
        } else {

            alert('No token found in local storage. User may not be logged in');
        }
    };

    return (
        <>
            <div id="right-panel" className="right-panel">
                <header id="header" className="header">
                    <div className="header-menu">
                        <div className="col-sm-10">
                            <div className="page-header ">
                                <h1>Sion Datamatics india Pvt Ltd</h1>
                                <div className="page-title">
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-2 items-right  custom1">
                            <button className="nav-link cursor-pointer" onClick={handleLogout}><i className="fa fa-power-off text-danger" /> logout </button>
                            <div className="user-menu dropdown-menu">
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        </>
    )
}

export default Dheader;