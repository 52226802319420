import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SidePanel from '../Admin-Dashbord/SidePanel';
import Dheader from '../Admin-Dashbord/header';

function CategoryUpdate() {
  const { id } = useParams();
  const [updateCategory, setUpdateCategory] = useState('');
  const [showdata, setShowdata] = useState([]);
  const [userid, setUserid] = useState();

  // logout and Centerpanel 
  const navigate = useNavigate();

  const FormData = {
    id: userid,
    title: updateCategory,
  }

  useEffect(() => {
    axios.get(`https://www.sdmipl.com/portal/api/category_single_record/${id}`)
      .then((res) => {
        setShowdata(res.data.records)
        setUserid(res.data.records.id)
      })
      .catch((error) => {
        console.error('Error fetching data:', error);

      });
  }, [id]);

  // send Updated title with id ========= 

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://www.sdmipl.com/portal/api/category-update', FormData);
      if (response.data.error === true) {
        navigate('/catagory-view')
        alert(response.data.message);
      } else {
        alert(response.data.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (

    <div className='d-flex'>
      <SidePanel />
      < section className="right-dash">
        <Dheader />
        <section className="container mt-5 col-lg-12">
          <div className='card create'>
            <h2 className='text-center '>Category Title Update</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="categoryTitle">Category Update : </label>
                <input
                  type="text"
                  className="form-control"
                  id="categoryTitle"
                  defaultValue={showdata.title}
                  onChange={(e) => setUpdateCategory(e.target.value)}
                  name="categoryTitle"
                  placeholder="Enter your Category Title"
                />
              </div>
              <div className="btn">
                <input type="submit" className="btn btn-warning" value="Submit" />
              </div>
            </form>
          </div>
        </section>
      </section>

    </div>

  )

}

export default CategoryUpdate; 
