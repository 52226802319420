import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import SidePanel from '../../Admin-Dashbord/SidePanel';
import { Table, Thead, Tbody, Th, Td, Tr } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import Reactpaginate from 'react-paginate';
import Dheader from '../../Admin-Dashbord/header';

const ContactView = () => {
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [pageNumber, setPageNumber] = useState(0);
    const userPerPage = 10;
    // logout and Centerpanel 
    const navigate = useNavigate();
    useEffect(() => {
        axios.get('https://www.sdmipl.com/portal/api/contact-list')
            .then((res) => {
                setData(res.data.records);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    // Pagination 
    const pageCount = Math.ceil(data.length / userPerPage);
    const offset = pageNumber * userPerPage;

    // logout Functionality
    const handleLogout = () => {
        const token = localStorage.getItem('token');
        if (token) {
            axios.post('https://www.sdmipl.com/portal/api/logout', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((res) => {
                    alert(res.data.message);
                    localStorage.removeItem('token');
                    navigate('/login');
                })
                .catch((error) => {
                    console.error('Error logging out:', error);
                });
        } else {

            alert('No token found in local storage. User may not be logged in');
        }
    };

    return (
        <>
            <div className='d-flex'>
                <SidePanel />
                < section className="right-dash">
                    <Dheader />
                    <section className="intro">
                        <div className="bg-image h-100" style={{ backgroundColor: '#f5f5f5' }}>
                            <div className="mask d-flex align-items-center h-100">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-12">
                                            <div className="card create">
                                                <div className="card-body p-0">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <input
                                                            className="form-control my-3 w-50 input-custom border-2 border-primary" type="search" placeholder='serach by Project Title' value={search} onChange={(e) => setSearch(e.target.value)} />
                                                    </div>
                                                    <div className="table-responsive">
                                                        <Table className="table table-striped mb-0 w-100 mt-3">
                                                            <Thead style={{ backgroundColor: 'lightBlue' }}>
                                                                <Tr>
                                                                    <Th scope="col">S.NO</Th>
                                                                    <Th scope="col">Full-Name</Th>
                                                                    <Th scope="col">Email</Th>
                                                                    <Th scope="col">Contact</Th>
                                                                    <Th scope="col">Website</Th>
                                                                    <Th scope="col">Description</Th>
                                                                    <Th scope="col">Date</Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {data
                                                                    .filter((item) =>
                                                                        search.toLowerCase() === '' ||
                                                                        item.firstname.toLowerCase().includes(search.toLowerCase()) ||
                                                                        item.lastname.toLowerCase().includes(search.toLowerCase()) ||
                                                                        item.email.toLowerCase().includes(search.toLowerCase()) ||
                                                                        item.mobile.toLowerCase().includes(search.toLowerCase()) ||
                                                                        item.created_at.slice(0, 10).toLowerCase().includes(search.toLowerCase())
                                                                    )
                                                                    .slice(offset, offset + userPerPage)
                                                                    .map((item, index) => (
                                                                        <Tr key={item.id}>
                                                                            <Td>{index + 1}</Td>
                                                                            <Td>{item.firstname} {item.lastname}</Td>
                                                                            <Td>
                                                                                <Link className="text-dark" to={`https://mail.google.com/mail/u/0/?fs=1&to=${encodeURIComponent(item.email)}&tf=cm`} target='_blank' > {item.email} </Link>
                                                                            </Td>
                                                                            <Td>
                                                                                <Link className="text-dark text-decoration-none" to={`tel:${item.mobile}`} target='_blank'> {item.mobile}</Link>
                                                                            </Td>
                                                                            <Td>{item.website}</Td>
                                                                            <Td style={{ width: 100 }}>{item.description}</Td>
                                                                            <Td>{new Date(item.created_at.slice(0, 10)).toLocaleDateString('en-GB')}</Td>
                                                                        </Tr>
                                                                    ))}
                                                            </Tbody>
                                                        </Table>

                                                        {/* pagination */}
                                                        <div className='pagination pagination-lg justify-content-center text-center mt-3'>
                                                            <Reactpaginate
                                                                nextLabel={<>Next </>}
                                                                pageCount={pageCount}
                                                                onPageChange={(selectedPage) => setPageNumber(selectedPage.selected)}
                                                                disabledClassName={'pagination__link--disabled'}
                                                                previousLabel="Previous"
                                                                containerClassName='pagination justify-content-center pagination-lg pagination-sm pagination-col'
                                                                breakClassName='page-item'
                                                                breakLinkClassName='page-link'
                                                                previousClassName='page-item'
                                                                previousLinkClassName='page-link'
                                                                nextClassName='page-item'
                                                                nextLinkClassName='page-link'
                                                                activeClassName='active  pb-3'
                                                                pageClassName='page-item ml-1 mr-1'  // Custom class for pagination item
                                                                pageLinkClassName='page-link'  // Custom class for pagination link
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

            </div>
        </>

    )

}

export default ContactView
