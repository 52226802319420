import React, { useEffect, useState } from 'react'
import axios from 'axios';
import SidePanel from '../Admin-Dashbord/SidePanel';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import Dheader from '../Admin-Dashbord/header';
import RichText from '../Ritchtext';

const Create_single_Service = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        service_title: '',
        service_charge: '',
        service_description: '',
        plan_type: '',
        page: '',
    });
    const [services, setService] = useState([]);
    console.log(formData, 'formData');
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleChangeselect = (e) => {
        const selectedOption = JSON.parse(e.target.value);
        setFormData({ ...formData, page: selectedOption.service_card_title });
        // console.log('Selected ID:', selectedOption.id);
        // console.log('Selected Title:', selectedOption.service_card_title);
    };
    const handleRichTextChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    useEffect(() => {
        const service = async () => {
            try {
                const res = await axios.get(`https://sdmipl.com/portal/api/view-service-card`);
                setService(res.data.result);
                // console.warn('servicepgeNew', res.data.result);
            } catch (error) {
                console.error('error fetching', error);
            }
        }
        service();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = axios.post('https://www.sdmipl.com/portal/api/add-service-plan', formData)
            if (response.status === 200) {
                navigate('/view_single_serviceplan')
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 4000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    },
                });
                Toast.fire({
                    icon: 'success',
                    title: 'record has been Added ',
                });
            } else {
                navigate('/view_single_serviceplan');
            }
        } catch (err) {
            console.log(err,'something is wrong');
            navigate('/view_plans');
        }

    }

    return (
        <>
            <div className='d-flex'>
                <SidePanel />
                < section className="right-dash">
                    <Dheader />
                    <div className="container  mt-3 col-lg-12">
                        <div className='card create'>
                            <h2 className='text-center'> Single Service Plans </h2>
                            <div className="form-group">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="name"> Service Title : </label>
                                        <input type="text" onChange={handleChange} className="form-control" name="service_title" placeholder="Enter Service title" />
                                    </div>
                                    <div className='form-group col-lg-6'>
                                        <label htmlFor='plantype'> Plan Type </label>
                                        <select className='form-control form-select' onChange={handleChange} name='plan_type' >
                                        <option default >Select Type of  Plan</option>
                                            <option value='Basic plan'>Basic Plan</option>
                                            <option value='Advance Plan'>Advance Plan</option>
                                            <option value='Full Plan'>Full Plan</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="name"> Service Charge : </label>
                                        <input type="text" onChange={handleChange} className="form-control" name="service_charge" placeholder="Enter service_charge" />
                                    </div>
                              
                                    <div className='form-group col-lg-6'>
                                        <label htmlFor='page'>To Navigate on Page</label>
                                        {services && services.length > 0 && (
                                            <select onChange={handleChangeselect}  className='form-control form-select' name='page'>
                                                <option>Select to Navigate </option>
                                                {services.map((item) =>
                                                    <option value={JSON.stringify({ id: item.id, service_card_title: item.service_card_title })} key={item.id}>
                                                        {item.service_card_title}
                                                    </option>
                                                )}
                                            </select>
                                        )}
                                    </div>
                                    
                                    <div className="col-lg-12">
                                        <label htmlFor="service_description" className="form-label"> Service Description : </label>
                                        <RichText
                                            name="service_description"
                                            placeholder={"Service  Description"}
                                            onChange={handleRichTextChange}
                                            className='form-control'
                                            rows={4}
                                        />
                                    </div>
                                    <div className='btn col-12'><input type="submit" className="btn btn-warning d-flex justify-content-end" value="Submit" /></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>

    )

}

export default Create_single_Service;