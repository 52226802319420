// ================================================      {  menu_options  }     ==============================
import React, { useState } from 'react'
import axios from 'axios'
import SidePanel from '../Admin-Dashbord/SidePanel'
import { useNavigate } from 'react-router';
import Dheader from '../Admin-Dashbord/header';

const Menu = () => {
    const [header, setHeader] = useState('')
    const navigate = useNavigate();
    const FormData = {
        title: header,

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('https://www.sdmipl.com/portal/api/menu-create', FormData)
            .then((res) => {
                if (res.data.err === "true") {
                    alert(res.data.message);
                    navigate('/menu-view')
                } else {
                    alert("internal server failled")
                }
            })
            .catch((error) => {
                console.error('Error submitting form:', error);
            });
    };

    // logout Functionality
    const handleLogout = () => {
        const token = localStorage.getItem('token');
        if (token) {
            axios.post('https://www.sdmipl.com/portal/api/logout', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((res) => {
                    alert(res.data.message);
                    localStorage.removeItem('token');
                    navigate('/login');
                })
                .catch((error) => {
                    console.error('Error logging out:', error);
                });
        } else {

            alert('No token found in local storage. User may not be logged in');
        }
    };

    return (
        <>
            <div className='d-flex'>
                <SidePanel />
                < section className="right-dash">
                    <Dheader />
                    <div className="container mt-3 col-lg-12">
                        <div className='card create'>
                            <h2 className='text-center'> Create Menu </h2>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="header-menu-1">Menu Title :</label>
                                    <input type="text" className="form-control" id="header-menu-1" onChange={(e) => setHeader(e.target.value)} name="header-menu-1" placeholder="Enter your Category Title" />
                                </div>

                                <div className='btn'><input type="submit" className="btn btn-warning" value="Submit" /></div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>

        </>

    )
}

export default Menu;