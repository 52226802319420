import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import SidePanel from '../Admin-Dashbord/SidePanel';
import Swal from 'sweetalert2';
import Dheader from '../Admin-Dashbord/header';

const Submenu_update = () => {
    const { param } = useParams();
    const { id } = useParams();
    const [postTitles, setPostTitles] = useState([]);
    const [getdata, setgetdata] = useState([]);
    const [getid, setgetid] = useState();
    const navigate = useNavigate();
    const [formData, setformdata] = useState({
        id: "",
        menu_id: "",
        title: "",
        page_id: ""
    });
    // console.log(formData)
    useEffect(() => {
        // Fetch data from the backend
        axios.get(`https://www.sdmipl.com/portal/api/submenu_single_record/${id}`)
            .then((res) => {
                const postData = res.data.records;

                setformdata({
                    id: postData.id,
                    menu_id: postData.menu_id,
                    title: postData.title,
                    page_id: postData.page_id
                });

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });

        // Get IDS Data
        const apiUrl = 'https://www.sdmipl.com/portal/api/menu-view';
        axios.get(apiUrl)
            .then((response) => {
                setPostTitles(response.data.records);

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });

        // Get Page link Data
        axios.get('https://www.sdmipl.com/portal/api/get-page-list')
            .then((response) => {
                setgetdata(response.data.pages);

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });

    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Combine form data and selected ID
            const FormData = {
                ...formData,
                page_id: parseInt(getid), // Assuming 'getid' is what you intend to use here
            };

            // console.warn(FormData);
            const response = await axios.post('https://www.sdmipl.com/portal/api/submenu-update', FormData);
            //  console.log(response);
            if (response.data.error === true) {
                navigate('/submenu-view')
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 4000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    },
                });
                Toast.fire({
                    icon: 'success',
                    title: response.data.message,
                });
            } else {
                alert(response.data.message);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setformdata({
            ...formData,
            [name]: value,
        });
    };

  return (
        <>
            <div className='d-flex'>
                <SidePanel />
                < section className="right-dash">
                    <Dheader />
                    <div className="container mt-3 col-lg-12">
                     <div className='card create'>
                        <h2 className='text-center'>Sub Menu Update</h2>
                        <div className="form-group">
                            <form onSubmit={handleSubmit}>
                                <div className='form-group mt-5 text-center'>
                                    <label htmlFor="menu_id" className='mr-3'>Menu title select : </label>
                                    <select name="menu_id" className='p-1  pl-3 pr-3  text-center rounded' id="menu_id" onChange={handleChange}>
                                        <option value="">Select a Menu title</option>
                                        {postTitles && postTitles.map((item, index) => (
                                            <option key={index} value={item.id}
                                                selected={(formData.menu_id === parseInt(item.id)) ? true : false}>
                                                {item.title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group ">
                                    <label htmlFor="post-title" >Sub Menu Title :</label>
                                    <input
                                        type='text'
                                        className="form-control p-1 rounded"
                                        id="post-title"
                                        name="title"
                                        placeholder="Enter your Post Title"
                                        onChange={handleChange}
                                        value={formData.title}
                                    />
                                </div>
                                <div className='form-group mt-2 text-center'>
                                    <label htmlFor="catagory-title" className='mr-3'> Page Link :</label>
                                    <select name="catagory-title" id="catagory-title" className='p-1 text-center rounded' onChange={(e) => setgetid(e.target.value)}>
                                        <option value="">Select a Menu title</option>
                                        {getdata && getdata.map((item, index) => (
                                            <option key={index} value={item.id}
                                                selected={(formData.page_id === parseInt(item.id)) ? true : false}>
                                                {item.title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='btn'><input type="submit" className="btn btn-warning" value="Submit" /></div>
                            </form>
                        </div>
                    </div>
                    </div>
                </section>
            </div>

        </>
    );
};

export default Submenu_update;
