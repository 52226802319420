import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import SidePanel from '../Admin-Dashbord/SidePanel';
import Swal from 'sweetalert2';
import Dheader from '../Admin-Dashbord/header';

const Update_seo = () => {
    const { id } = useParams();
    const [FormData, setFormData] = useState({
        title: '',
        package_description: '',
        service_charge: '',
        seo_title: '',
        seo_description: "",
        seo_keywords: '',
        canonical_link: ''
    });

    const navigate = useNavigate()
    useEffect(() => {
        axios.get(`https://sdmipl.com/portal/api/services-single-record/${id}`)
            .then((res) => {
                const postData = res.data.records;
                setFormData({
                    title: postData.title,
                    package_description: postData.package_description,
                    service_charge: postData.service_charge,
                    seo_title: postData.seo_title,
                    seo_description: postData.seo_description,
                    seo_keywords: postData.seo_keywords,
                    canonical_link: postData.canonical_link
                });
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [id]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`https://sdmipl.com/portal/api/services-update/${id}`, FormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log("dsd", response)
            console.warn(FormData, 'submission Data')
            if (response.status === 200) {
                navigate('/view_plans')
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 4000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    },
                });
                Toast.fire({
                    icon: 'success',
                    title: 'record has been updated ',
                });
            } else {
                navigate('/view_plans');
                alert("Internal server failed");
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert("Error submitting records. Please try again.");
        }
    };
    const handleChange = (e) => {
        const { name, value, type } = e.target;
        if (type === 'file') {
            setFormData({
                ...FormData,
                [name]: e.target.files[0],
            });
        } else {
            setFormData({
                ...FormData,
                [name]: value,
            });
        }
    };
    return (
        <>
            <div className='d-flex'>
                <SidePanel />
                < section className="right-dash">
                   <Dheader />
                    <div className="container mt-5 col-lg-12">
                        <div className='card create'>
                        <h2 className='text-center'> Seo Update</h2>
                        <div className="form-group ">
                            <form onSubmit={handleSubmit} encType='multiply'>
                                <div className="form-group col-lg-6">
                                    <label htmlFor="name"> Title : </label>
                                    <input type="text" onChange={handleChange} className="form-control" id="title" name="title" placeholder="Enter title" value={FormData.title} />
                                </div>
                                <div className="form-group col-lg-6">
                                    <label htmlFor="name"> Seo Title : </label>
                                    <input type="text" onChange={handleChange} className="form-control" id="seo_title" name="seo_title" placeholder="Enter seo_title" value={FormData.seo_title} />
                                </div>
                                <div className="form-group col-lg-6">
                                    <label htmlFor="name"> Seo keywords </label>
                                    <input type="text" onChange={handleChange} className="form-control" id="seo_keywords" name="seo_keywords" placeholder="Enter seo_keywords" value={FormData.seo_keywords} />
                                </div>
                                <div className="form-group col-lg-6">
                                    <label htmlFor="seo_description">Seo description : </label>
                                    <input type="text" onChange={handleChange} className="form-control" id="seo_description" name="seo_description" placeholder="Enter seo_description" value={FormData.seo_description} />
                                </div>
                                <div className="form-group col-lg-6">
                                    <label htmlFor="name"> Canonical link : </label>
                                    <input type="text" onChange={handleChange} className="form-control" id="canonical_link" name="canonical_link" placeholder="Enter canonical_link" value={FormData.canonical_link} />
                                </div>
                                <div className="form-group col-lg-6">
                                    <label htmlFor="name"> Service Charge : </label>
                                    <input type="text" onChange={handleChange} className="form-control" id="service_charge" name="service_charge" placeholder="Enter service_charge" value={FormData.service_charge} />
                                </div>
                                <div className="form-group col-lg-12">
                                    <label htmlFor="name"> Package Description : </label>
                                    <textarea type="text" onChange={handleChange} className="form-control" id="package_description" name="package_description" placeholder="Enter package_description" value={FormData.package_description} />
                                </div>
                                <div className='btn'><input type="submit" className="btn btn-warning" value="Submit" /></div>
                            </form>
                        </div>
                        </div>
                    </div>
                </section>
            </div>
        </>

    )

}

export default Update_seo;
