import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TokenProvider from './Dashbord/Modules/Auth/TokenProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <TokenProvider>

  <React.StrictMode>
    <App />
  </React.StrictMode>
  </TokenProvider>
);
reportWebVitals();