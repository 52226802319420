import React, { useEffect, useState } from 'react'
import axios from 'axios';
import SidePanel from '../Admin-Dashbord/SidePanel';
import Dheader from '../Admin-Dashbord/header';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

function Carrer_update() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [FormData, setFormData] = useState({
        job_title: '',
        job_description: '',
        job_location: '',
        job_end_date: '',
        job_mode: '',
        job_type: '',
        experience_required: '',
        total_post: '',
        status: '',
    })
    const [jobaction, setJobAction] = useState({
        status: '',
    })
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`https://www.sdmipl.com/portal/api/view-single-jobpost/${id}`);
                setFormData(res.data.result)
                console.log('jobpostt', res.data.result);
            } catch (error) {
                console.log('Something is wrong', error);
            }
        };
        fetchData();
    }, []);
    // view-single-jobpost/{id}
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`https://www.sdmipl.com/portal/api/update-jobpost/${id}`,
                FormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log("updatejob", response)
            console.warn(FormData, 'submission Data')

            if (response.status === 200) {
                navigate('/carrer_view')
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 4000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    },
                });
                Toast.fire({
                    icon: 'success',
                    title: 'record has been updated ',
                });
            } else {
                navigate('/carrer_view');
                alert("Internal server failed");
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert("Error submitting records. Please try again.");
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...FormData,
            [name]: value,
        });
    };
    const handleChangeStatus = async (value, id) => {

        const jobresponse = await axios.post(`https://www.sdmipl.com/portal/api/update-jobpost-status/${id}`, {
            status: value
        })
        setFormData((prev) => prev.id === id ? { ...prev, status: value } : prev)

        console.log('jobaction', jobresponse)
    }
    return (
        <div className='d-flex'>
            <SidePanel />
            <section className="right-dash">
                <Dheader />
                <div className="container">
                    <div className="row">
                        <div className="card create">
                            <h2 className='text-center'>Create Career Opportunities</h2>
                            <div className="form-group">
                                <form onSubmit={handleSubmit} encType='multipart/form-data'>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="job_title">Job Title:</label>
                                        <input type="text" onChange={handleChange} className="form-control" id="job_title" name="job_title" placeholder="Enter your job title" value={FormData.job_title} required />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="job_type">Job Type:</label>
                                        <input type="text" onChange={handleChange} className="form-control" id="job_type" name="job_type" placeholder="Enter your job_type" value={FormData.job_type} required />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="job_mode">Job Mode:</label>
                                        <input type="text" onChange={handleChange} className="form-control" id="job_mode" name="job_mode" placeholder="Enter your job_mode" value={FormData.job_mode} required />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="experience_required">Experience Required:</label>
                                        <select className='form-select' onChange={handleChange} id="experience_required" value={FormData.experience_required} name="experience_required">
                                            <option value='1'>1 Year </option>
                                            <option value='2'>2 Year</option>
                                            <option value='3'>3 Year</option>
                                            <option value='4'>4 Year</option>
                                            <option value='5'>5 Year</option>
                                        </select>

                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="job_location">Job Location:</label>
                                        <input type="text" onChange={handleChange} className="form-control" id="job_location" name="job_location" placeholder="Enter your job location" value={FormData.job_location} required />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="job_end_date">Job End Date :</label>
                                        <input type="date" onChange={handleChange} className="form-control" id="job_end_date" name="job_end_date" placeholder="Enter your job_end_date" value={FormData.job_end_date} required />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="total_post">Total Post:</label>
                                        <input type="text" onChange={handleChange} className="form-control" id="total_post" name="total_post" placeholder="Enter your total_post" value={FormData.total_post} required />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="total_post">Job Status:</label>
                                        <select onChange={(e) => handleChangeStatus(e.target.value, FormData.id)} className="form-control" name='status' value={FormData.status}>
                                            <option value={1}> Activate </option>
                                            <option value={0}> De-actiavte </option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-12">
                                        <label htmlFor="job_description">Job Description</label>
                                        <textarea className="form-control" onChange={handleChange} id="job_description" name="job_description" placeholder="Enter job_description" value={FormData.job_description} required />
                                    </div>
                                    <div className='btn'><input type="submit" className="btn btn-warning" value="Update" /></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Carrer_update;