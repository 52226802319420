import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import SidePanel from '../Admin-Dashbord/SidePanel';
import { Table, Thead, Tbody, Th, Td, Tr } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import Reactpaginate from 'react-paginate';
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import Dheader from '../Admin-Dashbord/header';

const Testimunialview = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const userPerPage = 5;

  useEffect(() => {
    axios.get('https://www.sdmipl.com/portal/api/testimonial-view')
      .then((res) => {
        setData(res.data.records);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // Delete Funtion for delete title  =>
  const handleDelete = async (id) => {
    const FormData = { id: id }
    try {
      const response = await axios.post('https://www.sdmipl.com/portal/api/testimonial-delete', FormData
      )
      //console.log(response.data.message)
      if (response.status === 200) {
        navigate('/testimunial-view')
        alert(response.data.message)
      } else {
        alert(response.data.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  };
  // logout and Centerpanel 
  const navigate = useNavigate();

  const pageCount = Math.ceil(data.length / userPerPage);
  const offset = pageNumber * userPerPage;

  // console.log(data)
  return (
    <>
      <div className='d-flex'>
        <SidePanel />
        < section className="right-dash">
          <Dheader />
          <section className="">
            <div className="bg-image h-100" style={{ backgroundColor: '#f5f5f5' }}>
              <div className="mask d-flex align-items-center h-100">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <div className="card create">
                        <div className="card-body p-0">
                            <h2 className='text-center'>Testimonial's View </h2>
                          <div className="d-flex align-items-center justify-content-center">
                            <input
                              className="form-control my-3 w-50 input-custom border-2 border-primary"
                              type="search"
                              placeholder="Search by Project Title"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                          </div>
                          <div className="table-responsive table-scroll" data-mdb-perfect-scrollbar="true">
                            <Table className="table table-striped mb-0 mt-3">
                              <Thead style={{ backgroundColor: 'lightBlue' }}>
                                <Tr>
                                  <Th scope="col">S.No</Th>
                                  <Th scope="col">Name</Th>
                                  <Th scope="col">Designation</Th>
                                  <Th scope="col">Testimony</Th>
                                  <th scope="col">Image</th>
                                  <Th scope="col">Action</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {data
                                  .filter((item) =>
                                    search.toLowerCase() === '' ? true : item.name.toLowerCase().includes(search.toLowerCase()) ||
                                      search.toLowerCase() === '' ? true : item.designation.toLowerCase().includes(search.toLowerCase())
                                  )
                                  .slice(offset, offset + userPerPage)
                                  .map((item, index) => (
                                    <Tr key={item.id}>
                                      <Td>{index + 1}</Td>
                                      <Td>{item.name}</Td>
                                      <Td>{item.designation}</Td>
                                      <Td>{item.testimony}</Td>
                                      <Td>
                                        <img src={item.image} alt="project" width="120" height="135" />
                                      </Td>
                                      <Td className='d-flex m-2'>
                                        <Link to={`/testimony-update/${item.id}`} style={{ textDecoration: 'none', color: 'white' }}>
                                          <button type="button" className="btn btn-success "><span><FaEdit /></span>
                                          </button>
                                        </Link>
                                        <button
                                          type="button"
                                          className="btn btn-danger ml-2"
                                          onClick={(e) => {
                                            handleDelete(item.id);
                                          }}
                                        ><RiDeleteBin5Fill />
                                        </button>
                                      </Td>
                                    </Tr>
                                  ))}
                              </Tbody>
                            </Table>
                            {/* pagination */}
                            <div className='pagination pagination-lg justify-content-center text-center mt-3'>
                              <Reactpaginate
                                nextLabel={<>Next </>}
                                pageCount={pageCount}
                                onPageChange={(selectedPage) => setPageNumber(selectedPage.selected)}
                                disabledClassName={'pagination__link--disabled'}
                                previousLabel="Previous"
                                containerClassName='pagination justify-content-center pagination-lg pagination-sm pagination-col'
                                breakClassName='page-item'
                                breakLinkClassName='page-link'
                                previousClassName='page-item'
                                previousLinkClassName='page-link'
                                nextClassName='page-item'
                                nextLinkClassName='page-link'
                                activeClassName='active  pb-3'
                                pageClassName='page-item ml-1 mr-1'  // Custom class for pagination item
                                pageLinkClassName='page-link'  // Custom class for pagination link
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>

    </>

  )

}

export default Testimunialview;