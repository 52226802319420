import React from 'react';
import TokenApi from './TokenApi'; 

const TokenProvider = ({ children }) => {
  const token = localStorage.getItem('token');
  const headers = {
    'Authorization': `Bearer ${token}`
  };
  return (
    <div>
      <TokenApi.Provider value={{ headers, token }}>
        {children}
      </TokenApi.Provider>
    </div>
  );
};

export default TokenProvider;
