import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { MDBContainer, MDBRow, MDBCol, MDBInput } from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';

const Login = ({ onLogin }) => {
  const [formData, setFormData] = useState({
    admin_id: '',
    password: '',
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('https://sdmipl.com/portal/api/super-admin-login', formData);
      if (res.status === 200) {
        const accessToken = res.data.token;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('id', res.data.id);

        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: 'Admin Login Successfully!',
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        setFormData({
          admin_id: '',
          password: '',
        })
        onLogin();
        navigate('/admin');
      } else {
        throw new Error('Invalid login');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Login Failed',
        text: error.response?.data?.message || 'Please enter a valid Admin ID & password',
      });
      console.error('Login error:', error);
    }
  };

  return (
    <MDBContainer className="my-5 gradient-form">
      <MDBRow className='container'>
        <MDBCol col='12' className="mb-2 shadow-lg">
          <div className="d-flex flex-column ms-5">
            <div className="text-center">
              <h4 className="mt-1 mb-5 pb-1 text-danger">
                <span className="text-primary ">SDMIPL</span> Admin Login
              </h4>
            </div>
            <p className="text-primary mt-5">Please login to your account</p>

            <MDBInput wrapperClass='mb-4 mt-2' label='Email address' onChange={handleChange} name='admin_id' type='text' />
            <MDBInput wrapperClass='mb-4' label='Password' onChange={handleChange} id='form2' name='password' type='password' />
            <div className="text-center pt-1 mb-5 pb-1">
              <button className="btn btn-success mb-4 w-100 gradient-custom-2" onClick={handleLogin}>Log - in</button>
            </div>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Login;
