import React, { useState } from 'react';
import axios from 'axios';
import SidePanel from '../Admin-Dashbord/SidePanel';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import Dheader from '../Admin-Dashbord/header';
const Create_gallery = () => {
    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);
    const [description, setDescription] = useState('');
    // const [images,setImages] = useState('');

    const navigate = useNavigate();
    const handleImageChangeimg = (e) => {
        const file = e.target.files[0];
        setImage(file);
    };
    // multiple img
    // const handleImageChange = (event) => {
    //     const files = Array.from(event.target.files);
    //     setImages(files);
    // };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        if (image) {
            formData.append('gallery_image', image);
        }
        try {
            const response = await axios.post('https://sdmipl.com/portal/api/gallery-section-add', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 4000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    },
                });
                Toast.fire({
                    icon: 'success',
                    title: 'Data inserted Successfully',
                });
                navigate('/gallery');
            } else {
                alert("Internal server failed");
            }
        } catch (error) {
            console.error('Error submitting Images:', error);
            alert("Error submitting form. Please try again.");
        }
    };

    return (
        <>
                <div className='d-flex'>
                    <SidePanel />
                    <section className="right-dash">
                        <Dheader />
                        <div className="container mt-3 col-lg-12">
                            <div className='row'>
                            <div className='card create'>
                                <h2 className='text-center'>Gallery Section</h2>
                                <div className="form-group">
                                    <form onSubmit={handleSubmit} encType='multipart/form-data'>
                                        <div className="form-group">
                                            <label htmlFor="ImageTitle">Image Title:</label>
                                            <input type="text" onChange={(e) => setTitle(e.target.value)} className="form-control" id="ImageTitle" name="ImageTitle" placeholder="Enter your Image Title" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="ImageDescription">Image Description</label>
                                            <textarea className="form-control" onChange={(e) => setDescription(e.target.value)} id="Image-description" name="ImageDescription" placeholder="Enter Image Description" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="job-image">Image:</label>
                                            <input type="file" className="form-control" id="job-image" name="gallery_image" onChange={handleImageChangeimg} />
                                            {image && (
                                                <div className='imgGallery'>
                                                    <p>Selected file: {image.name}</p>
                                                    <img src={URL.createObjectURL(image)} alt="Selected-item" width="100" />
                                                </div>
                                            )}
                                        </div>
                                        {/* multiple image selection*/}

                                        {/* <label htmlFor="gallery_image">Image:</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="gallery_image"
                                    name="gallery_image"
                                    multiple
                                    onChange={handleImageChange}
                                />
                                
                                {images.length > 0 && (
                                    <div>
                                        {images.map((image, index) => (
                                            <div className='imgGallery' key={index}>
                                                <p>Selected file: {image.title}</p>
                                                <img 
                                                    src={URL.createObjectURL(image)}
                                                    alt={`Selected-item-${index}`}
                                                    width="100"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )} */}

                                        {/* multiple image selection*/}
                                        <div className='btn'><input type="submit" className="btn btn-warning" value="Submit" /></div>
                                    </form>
                                </div>
                            </div>
                            </div>
                            
                        </div>
                    </section>
                </div>
        </>
    );
};

export default Create_gallery;
