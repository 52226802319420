import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import SidePanel from '../Admin-Dashbord/SidePanel';
import Swal from 'sweetalert2';
const MenuUpdate = () => {

  const { id } = useParams();
  const [updateCategory, setUpdateCategory] = useState('');
  const [slug, setSlug] = useState('');
  const [showdata, setShowdata] = useState([]);
  const [userid, setUserid] = useState();
  const [getid, setGetid] = useState();
  const [getdata, setGetdata] = useState([]);

  // Send Hedder From Backend  
  const token = localStorage.getItem('token');
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
    const apiUrl = 'https://www.sdmipl.com/portal/api/get-page-list';
    axios.get(apiUrl, { headers })
      .then((response) => {
        setGetdata(response.data.pages);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

    axios.get(`https://www.sdmipl.com/portal/api/menu_single_record/${id}`, { headers })
      .then((res) => {
        const data = res.data.records;
        setShowdata(data);
        setUserid(data.id);
        setUpdateCategory(data.title);
        setSlug(data.slug);
        setGetid(data.page_id.toString());
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const FormData = {
        id: userid,
        title: updateCategory,
        page_id: parseInt(getid),
        slug: slug,
      };

      const response = await axios.post('https://www.sdmipl.com/portal/api/menu-update', FormData, { headers });
      // console.log(response);

      if (response.data.error === true) {
        navigate('/menu-view')
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: 'success',
          title: response.data.message,
        });
      } else {
        alert(response.data.message);
      }
    } catch (err) {
      console.log(err);
    }
  };
  // logout and Centerpanel 
  const navigate = useNavigate();

  // logout Functionality
  const handleLogout = () => {

    if (token) {
      axios.post('https://www.sdmipl.com/portal/api/logout', { headers })
        .then((res) => {
          alert(res.data.message);
          localStorage.removeItem('token');
          navigate('/login');
        })
        .catch((error) => {
          console.error('Error logging out:', error);
        });
    } else {

      alert('No token found in local storage. User may not be logged in');
    }
  };

  const style = {
    display: 'flex',
    justifyContent: 'space-between'
  };
  return (
    <>
      <div className='seprate' style={style}>
        <SidePanel />
        < section className="right-dash">
          <div id="right-panel" className="right-panel">
            <header id="header" className="header">
              <div className="header-menu">
                <div className="col-sm-10">

                  <div className="page-header ">
                    <div className="page-title">
                      <h1>Sion Datamatics india Pvt Ltd</h1>
                    </div>
                  </div>

                </div>
                <div className="col-sm-2 items-right  custom1">
                  <button className="nav-link cursor-pointer" onClick={handleLogout}><i className="fa fa-power-off text-danger" /> logout </button>
                  <div className="user-menu dropdown-menu">
                  </div>
                </div>
              </div>
            </header>
          </div>
          <section className="container col-lg-12 mt-5">
            <h2 className='text-center'>Menu Update</h2>
            <form onSubmit={handleSubmit}>
              <div className='form-group '>
                <label htmlFor="menu-update">Menu Title Update : </label>
                <input
                  type="text"
                  className="form-control "
                  id="menu-update"
                  value={updateCategory}
                  onChange={(e) => setUpdateCategory(e.target.value)}
                  name="menu-update"
                  placeholder="Enter your Menu Title"
                />
              </div>

              <div className='form-group mt-2 text-center'>
                <label htmlFor="catagory-title" className='mr-3'>Menu Page Link :</label>
                <select className='text-center p-1 rounded' name="catagory-title" id="catagory-title" onChange={(e) => setGetid(e.target.value)}>
                  <option value="">Select a Menu title</option>
                  {getdata && getdata.map((item, index) => (
                    <option key={index} value={item.id} selected={showdata.page_id === item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className="btn">
                <input type="submit" className="btn btn-warning" value="Submit" />
              </div>
            </form>
          </section>
        </section>

      </div>

    </>
  );
};

export default MenuUpdate;
