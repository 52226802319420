import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import SidePanel from '../Admin-Dashbord/SidePanel';
import Dheader from '../Admin-Dashbord/header';
import Swal from 'sweetalert2';
import axios from 'axios';
import RichText from '../Ritchtext';

function Update_singel_Serviceplan() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [formData, setFormData] = useState({
        service_title: '',
        service_charge: '',
        service_description: ''
    });
    console.log(formData, 'formData');
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleRichTextChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`https://sdmipl.com/portal/api/view-single-service-plan/${id}`);
                setFormData(res.data.result);
                console.log(res.data.result, 'serviceplanupdate')
            } catch (error) {
                console.log(error, 'something is wrong')
            }
        };
        fetchData();
    }, [])
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = axios.post(`https://sdmipl.com/portal/api/update-service-plan/${id}`, formData)
            if (response.status === 200) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 4000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    },
                });
                Toast.fire({
                    icon: 'success',
                    title: 'record has been updated ',
                });
                navigate('/view_single_serviceplan')
            } else {
                navigate('/view_single_serviceplan');
            }
        } catch (err) {
            console.log(err)
            navigate('/view_single_serviceplan');
        }
    }
    return (
        <>
            <>
                <div className='d-flex'>
                    <SidePanel />
                    < section className="right-dash">
                        <Dheader />
                        <div className="container  mt-3 col-lg-12">
                            <div className='card create'>
                                <h2 className='text-center'> Update Single Service Plans </h2>
                                <div className="form-group">
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group col-lg-6">
                                            <label htmlFor="name"> Service Title : </label>
                                            <input type="text" onChange={handleChange} className="form-control" name="service_title" placeholder="Enter Service title" value={formData.service_title} />
                                        </div>
                                        <div className='form-group col-lg-6'>
                                            <label htmlFor='plantype' >Plan Type</label>
                                            <select className='form-control' onChange={handleChange} name='plan_type' value={formData.plan_type} >
                                                <option value='Basic plan'>Basic Plan</option>
                                                <option value='Advance Plan'>Advance Plan</option>
                                                <option value='Full Plan'>Full Plan</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label htmlFor="name"> Service Charge : </label>
                                            <input type="text" onChange={handleChange} className="form-control" name="service_charge" placeholder="Enter service_charge" value={formData.service_charge} />
                                        </div>
                                        <div className='form-group col-lg-6'>
                                            <label htmlFor='page'>Slug</label>
                                            <input type='text' onChange={handleChange} className='form-control'
                                                name='page' placeholder='Page to display' value={formData.page} />
                                        </div>
                                        <div className="col-lg-12">
                                            <label htmlFor="service_description" className="form-label"> Service Description : </label>
                                            <RichText
                                                name="service_description"
                                                placeholder={"Service  Description"}
                                                onChange={handleRichTextChange}
                                                className='form-control'
                                                rows={4}
                                                value={formData.service_description}
                                            />
                                        </div>
                                        <div className='btn'><input type="submit" className="btn btn-warning d-flex justify-content-end" value="Submit" /></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </>

        </>
    )
};

export default Update_singel_Serviceplan;