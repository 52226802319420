import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaHome, FaBars } from 'react-icons/fa';
import { Link, NavLink } from 'react-router-dom';
import { BiCog } from 'react-icons/bi';
import { BiCategory } from "react-icons/bi";
import { FaBlog } from "react-icons/fa";
import { GoProjectSymlink } from "react-icons/go";
import { FaMicroblog } from "react-icons/fa6";
import { TiThMenu } from "react-icons/ti";
import { MdMenuOpen } from "react-icons/md";
import { BsPersonVcard } from "react-icons/bs";
import { RiTeamFill } from "react-icons/ri";
import { FaImages } from "react-icons/fa6";
import { BiSolidLayer } from "react-icons/bi";
import { MdOutlineContactPhone } from "react-icons/md";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { FaCalculator } from "react-icons/fa";
import { GrServices } from "react-icons/gr";
import { MdOutlineLocalOffer } from "react-icons/md";
import { IoLogoGoogleplus } from "react-icons/io";
import logo from "./sionlogo.png";

const routes = [
    {
        path: '/admin',
        name: 'Home',
        icon: <FaHome />
    },
    {
        path: '#Menu',
        name: 'Menu',
        icon: <TiThMenu />,
        subRoutes: [
            {
                path: '/menu',
                name: 'Create - Menu',
                icon: <TiThMenu />
            },
            {
                path: '/menu-view',
                name: 'View - Menu',
                icon: <TiThMenu />
            }
        ]
    },
    {
        path: '#Sub - Menu',
        name: 'Sub - Menu',
        icon: <MdMenuOpen />,
        subRoutes: [
            {
                path: '/sub-menu',
                name: 'Create - Sub Menu',
                icon: <MdMenuOpen />
            },
            {
                path: '/submenu-view',
                name: 'View - Sub Menu',
                icon: <MdMenuOpen />
            }
        ]
    },
    {
        path: '#Category',
        name: 'Category',
        icon: <BiCategory />,
        subRoutes: [
            {
                path: '/catagory',
                name: 'Create - Category',
                icon: <BiCategory />
            },
            {
                path: '/catagory-view',
                name: 'Create - View',
                icon: <BiCategory />
            }
        ]
    },
    {
        path: '#Post And Blogs',
        name: 'Post And Blogs',
        icon: <FaBlog />
        ,
        subRoutes: [
            {
                path: '/post',
                name: 'Create - Post',
                icon: <FaBlog />

            },
            {
                path: '/postview',
                name: 'Post - view',
                icon: <FaBlog />

            }
        ]
    },
    {
        path: '#Services',
        name: 'Services',
        icon: <GrServices />,
        subRoutes: [
            {
                path: '/Create_service',
                name: 'Create_service',
                icon: <GrServices />,
            },
            {
                path: '/Update_services',
                name: 'Update_services',
                icon: <GrServices />
            }, {
                path: '/view_services',
                name: 'view_services',
                icon: <GrServices />
            }
        ],
    },
    {
        path: '#Project',
        name: 'Project',
        icon: <GoProjectSymlink />,
        subRoutes: [
            {
                path: '/project',
                name: 'Create - Project',
                icon: <GoProjectSymlink />

            },
            {
                path: '/projectview',
                name: 'View - Project',
                icon: <GoProjectSymlink />

            }
        ]
    },
    {
        path: '#Testimony',
        name: 'Testimony',
        icon: <FaMicroblog />
        ,
        subRoutes: [
            {
                path: '/testimunial',

                name: 'Testimony - Create',
                icon: <FaMicroblog />

            },
            {
                path: '/testimunial-view',
                name: 'Testimony - View',
                icon: <FaMicroblog />

            }
        ]
    },
    {
        path: '#Jobs',
        name: 'Jobs',
        icon: <BsPersonVcard />,
        subRoutes: [
            {
                path: '/job-create',
                name: 'Create - job',
                icon: <BsPersonVcard />
            },
            {
                path: '/job-view',
                name: 'View - job',
                icon: <BsPersonVcard />
            }
        ]
    },
    {
        path: '#Carrer',
        name: 'Carrer',
        icon: <MdOutlineLocalOffer />,
        subRoutes: [
            {
                path: '/carrer_create',
                name: 'Create Career',
                icon: <MdOutlineLocalOffer />
            },
            {
                path: '/carrer_view',
                name: 'Carrer View',
                icon: <MdOutlineLocalOffer />
            }
        ]
    },
    {
        path: '#Our Team',
        name: 'Our Team',
        icon: <RiTeamFill />,
        subRoutes: [
            {
                path: '/ourTeam',
                name: 'Create - ourTeam',
                icon: <RiTeamFill />
            },
            {
                path: '/ourTeam_view',
                name: 'View - OurTeam',
                icon: <RiTeamFill />
            }
        ]
    },
    {
        path: '#Gallery Section',
        name: 'Gallery Section',
        icon: <FaImages />,
        subRoutes: [
            {
                path: '/gallery',
                name: 'View-Gallery',
                icon: <FaImages />
            },
            {
                path: '/create_gallery',
                name: 'Create - Gallery',
                icon: <FaImages />
            },
            {
                path: '/gallery_update',
                name: 'Update - Gallery',
                icon: <FaImages />
            }
        ]
    },
    {
        path: '#Service Plan',
        name: 'Service_Plan',
        icon: <BiSolidLayer />,
        subRoutes: [
            {
                path: '/create_single_Service',
                name: 'Create - Service Plan',
                icon: <BiSolidLayer />
            },
            {
                path: '/view_single_serviceplan',
                name: 'View - Service Plan',
                icon: <BiSolidLayer />
            },
            // {
            //     path: '/update_singel_Serviceplan',
            //     name: 'Update - Service Plan',
            //     icon: <BiSolidLayer />
            // }
        ]
    },
    {
        path: '/Seo Plan',
        name: 'Seo Plan',
        icon: <BiSolidLayer />,
        subRoutes: [
            {
                path: '/create_plan',
                name: 'Create - Seo Plan',
                icon: <BiSolidLayer />
            },
            {
                path: '/view_plans',
                name: 'View - Seo Plan',
                icon: <BiSolidLayer />
            },
            {
                path: '/update_seo',
                name: 'Update - Seo Plan',
                icon: <BiSolidLayer />
            }
        ]
    },
    {
        path: '#Leads',
        name: 'Leads',
        icon: <MdOutlineContactPhone />,
        subRoutes: [
            {
                path: '/contact-view',
                name: 'Contact - Views',
                icon: <MdOutlineContactPhone />
            },
            {
                path:'/job_enquiry',
                name:'Job Enquiry',
                icon: <MdOutlineContactPhone />
            },
            {
                path:'/internship_enquiry',
                name:'Internship Enquiry',
                icon: <MdOutlineContactPhone />
            },
            {
                path: '/project_quaotaions',
                name: 'Project Quaotation',
                icon: <MdOutlineContactPhone />
            },
            {
                path: '/projectlead-view',
                name: 'Project - Leads',
                icon: <MdOutlineContactPhone />
            },
            {
                path: '/news_letter',
                name: 'News Letter',
                icon: <MdOutlineContactPhone />
            },
        ]
    },
    {
        path: '#Calculation',
        name: 'Calculation',
        icon: <FaCalculator />,
        subRoutes: [
            {
                path: '/website',
                name: 'Website',
                icon: <FaCalculator />
            },
            {
                path: '/graphics',
                name: 'Graphics',
                icon: <FaCalculator />
            },
            {
                path: '/editing',
                name: 'Video Editing',
                icon: <FaCalculator />
            },
            {
                path: '/social',
                name: 'Social Media Management',
                icon: <FaCalculator />
            },
        ]
    },
    {
        path: '/invoice',
        name: 'Invoice Generator',
        icon: <LiaFileInvoiceDollarSolid />,
    },
    {
        path:'/google_indexing',
        name:'Google Indexing',
        icon: <IoLogoGoogleplus />,
    },
    {
        path: '/#settings',
        name: 'Settings',
        icon: <BiCog />
    }
];

const SidePanel = ({ children }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [expandedCategory, setExpandedCategory] = useState('');

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const handleCategoryClick = (path) => {
        if (expandedCategory === path) {
            setExpandedCategory('');
        } else {
            setExpandedCategory(path);
        }
    };

    return (
        <>

            <div className="main-container">
                <motion.div animate={{ width: isOpen ? '230px' : '48px' }} className="sidebar">
                    <div className="top_section">
                        {isOpen && (
                            <Link className="navbar-brand" to="/admin">
                                <img className='img-fluid' src={logo} alt="Logo" width={130} />
                            </Link>
                        )}
                        <div className="bars">
                            <FaBars onClick={toggle} />
                        </div>
                    </div>
                    <hr />
                    <section className="routes">
                        {routes.map((route) => (
                            <div key={route.path}>
                                {route.subRoutes ? (
                                    <div>
                                        <div
                                            className={`link ${expandedCategory === route.path ? 'active' : ''}`}
                                            onClick={() => handleCategoryClick(route.path)}
                                        >
                                            <div className="icon">{route.icon}</div>
                                            <div className="link-name">{route.name}</div>
                                        </div>
                                        {expandedCategory === route.path && (
                                            <ul className="list-unstyled sub-routes">
                                                {route.subRoutes.map((subRoute) => (
                                                    <li key={subRoute.path}>
                                                        <NavLink
                                                            to={subRoute.path}
                                                            className="link ms-2"
                                                        >
                                                            <div className="icon">{subRoute.icon}</div>
                                                            <div className="link-name ">{subRoute.name}</div>
                                                        </NavLink>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                ) : (
                                    <NavLink
                                        to={route.path}
                                        className={`link ${expandedCategory === route.path ? 'active' : ''}`}
                                        onClick={() => handleCategoryClick(route.path)}
                                    >
                                        <div className="icon">{route.icon}</div>
                                        <div className="link-name">{route.name}</div>
                                    </NavLink>
                                )}
                            </div>
                        ))}
                    </section>
                    <main>{children}</main>
                </motion.div>
            </div>
        </>
    );
};

export default SidePanel;
