import React, { useState } from 'react';
import SidePanel from '../Admin-Dashbord/SidePanel';
import Dheader from '../Admin-Dashbord/header';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function Carrer_create() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        job_title:'',
        job_description: '',
        job_location:'',
        job_end_date: '',
        job_mode:'',
        job_type:'',
        experience_required:'',
        total_post:'',
        status:'',
    });
    console.log(formData, 'carrer create');
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://www.sdmipl.com/portal/api/add-jobpost', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 4000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    },
                });
                Toast.fire({
                    icon: 'success',
                    title: 'Data inserted Successfully',
                });
                navigate('/carrer_view');
            } else {
                alert("Internal server failed");
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert("Error submitting form. Please try again.");
        }
    };

    return (
        <div className='d-flex'>
            <SidePanel />
            <section className="right-dash">
                <Dheader />
                <div className="container">
                    <div className="row">
                        <div className="card create">
                            <h2 className='text-center'>Create Career Opportunities</h2>
                            <div className="form-group">
                                <form onSubmit={handleSubmit} encType='multipart/form-data'>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="job_title">Job Title:</label>
                                        <input type="text" onChange={handleChange} className="form-control" id="job_title" name="job_title" placeholder="Enter your job title" required/>
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="job_type">Job Type:</label>
                                        <input type="text" onChange={handleChange} className="form-control" id="job_type" name="job_type" placeholder="Enter your job_type" required/>
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="job_mode">Job Mode:</label>
                                        <input type="text" onChange={handleChange} className="form-control" id="job_mode" name="job_mode" placeholder="Enter your job_mode" required/>
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="experience_required">Experience Required:</label>
                                        <select onChange={handleChange} id="experience_required" name="experience_required">
                                        <option value='1'>1 Year </option>
                                            <option value='2'>2 Year</option>
                                            <option value='3'>3 Year</option>
                                            <option value='4'>4 Year</option>
                                            <option value='5'>5 Year</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="job_location">Job Location:</label>
                                        <input type="text" onChange={handleChange} className="form-control" id="job_location" name="job_location" placeholder="Enter your job location" required/>
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="job_end_date">Job End Date :</label>
                                        <input type="date" onChange={handleChange} className="form-control" id="job_end_date" name="job_end_date" placeholder="Enter your job_end_date" required/>
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="total_post">Total Post:</label>
                                        <input type="text" onChange={handleChange} className="form-control" id="total_post" name="total_post" placeholder="Enter your total_post" required/>
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="job_description">Job Description</label>
                                        <textarea className="form-control" onChange={handleChange} id="job_description" name="job_description" placeholder="Enter job_description" required/>
                                    </div>
                                    <div className='btn'><input type="submit" className="btn btn-warning" value="Submit" /></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Carrer_create;
