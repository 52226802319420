import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Admin from "./Dashbord/Modules/Admin-Dashbord/Admin";
import Catogaryview from "./Dashbord/Modules/Catagory/Catogary-view";
import CategoryUpdate from "./Dashbord/Modules/Catagory/catogory-update";
import Catagory from "./Dashbord/Modules/Catagory/create-catagory";
import Menu from "./Dashbord/Modules/Menu/Menu";
import PostUpdate from "./Dashbord/Modules/Post/Post-update";
import Post_Blog from "./Dashbord/Modules/Post/post-content";
import Postview from "./Dashbord/Modules/Post/Post-view";
import ProjectUpdate from "./Dashbord/Modules/Projects/ProjectUpdate";
import Project from "./Dashbord/Modules/Projects/Project";
import Projectview from "./Dashbord/Modules/Projects/Project-view";
import Submenuview from "./Dashbord/Modules/Submenu/Submenu-view";
import Sub_menu from "./Dashbord/Modules/Submenu/Sub_menu";
import TestimunialUpdate from "./Dashbord/Modules/Testimonial/Testimony-update";
import Testimunialview from "./Dashbord/Modules/Testimonial/Testimunial-view";
import Testimunial from "./Dashbord/Modules/Testimonial/Testimunials";
import Menuview from "./Dashbord/Modules/Menu/Menu-view";
import MenuUpdate from "./Dashbord/Modules/Menu/Menu-update";
import Submenu_update from "./Dashbord/Modules/Submenu/Sub_menu_update";
import Login from "./Dashbord/Modules/login/Login";
import JobCreate from "./Dashbord/Modules/Job/JobCreate";
import JobView from "./Dashbord/Modules/Job/JobView";
import JobUpdate from "./Dashbord/Modules/Job/JobUpdate";
import ContactView from "./Dashbord/Modules/Sion-formData/contact-us/ContactView";
import Project_lead_view from "./Dashbord/Modules/Sion-formData/contact-us/Project_lead_view";
import News_letter from "./Dashbord/Modules/Sion-formData/contact-us/news_letter";
import Invoicer from "./Dashbord/Modules/Invoice-Genrator/invoicer";
import CM2 from "./Dashbord/Modules/budget-calc/CM2";
import CM1 from "./Dashbord/Modules/budget-calc/cm1";
import CM3 from "./Dashbord/Modules/budget-calc/CM3";
import CM4 from "./Dashbord/Modules/budget-calc/CM4";
import OurTeam from "./Dashbord/Modules/OurTeam/ourTeam";
import OurTeam_view from "./Dashbord/Modules/OurTeam/ourTeam_view";
import Create_gallery from "./Dashbord/Modules/GallerySection/Create_gallery";
import Gallery from "./Dashbord/Modules/GallerySection/Gallery";
import Create_plan from "./Dashbord/Modules/SeoPlans/create_plan";
import Gallery_update from "./Dashbord/Modules/GallerySection/gallery_update";
import Update_seo from "./Dashbord/Modules/SeoPlans/update_seo";
import View_plans from "./Dashbord/Modules/SeoPlans/view_plans";
import Create_service from "./Dashbord/Modules/Services/Create_service";
import Update_services from "./Dashbord/Modules/Services/Update_services";
import View_services from "./Dashbord/Modules/Services/view_services";
import Carrer_create from "./Dashbord/Modules/Carrer/carrer_create";
import Carrer_view from "./Dashbord/Modules/Carrer/carrer_view";
import Carrer_update from "./Dashbord/Modules/Carrer/carrer_update";
import Create_single_Service from "./Dashbord/Modules/single_service_plan/create_single_Service";
import View_single_serviceplan from "./Dashbord/Modules/single_service_plan/view_single_serviceplan";
import Update_singel_Serviceplan from "./Dashbord/Modules/single_service_plan/update_singel_Serviceplan";
import Project_quaotaions from "./Dashbord/Modules/Sion-formData/contact-us/project_quaotaions";
import Internship_enquiry from "./Dashbord/Modules/Sion-formData/contact-us/internship_enquiry";
import Job_enquiry from "./Dashbord/Modules/Sion-formData/contact-us/job_enquiry";
import Google_indexing from "./Dashbord/Modules/Google_Indexing/google_indexing";

function App() {
  const [isLoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, []);
  const PrivateRoute = ({ element, ...rest }) => {
    return isLoggedIn ? (
      element
    ) : (
      <Navigate to="/login" replace state={{ from: rest.location }} />
    );
  };

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={isLoggedIn ? <Navigate to="/admin" /> : <Navigate to="/login" />}
          />
          <Route path="/admin" element={<PrivateRoute element={<Admin />} />} />
          <Route
            path="catagory"
            element={<PrivateRoute element={<Catagory />} />}
          />
          <Route
            path="invoice"
            element={<PrivateRoute element={<Invoicer />} />}
          />
          <Route
            path="project"
            element={<PrivateRoute element={<Project />} />}
          />
          <Route
            path="job-create"
            element={<PrivateRoute element={<JobCreate />} />}
          />
          <Route
            path="job-view"
            element={<PrivateRoute element={<JobView />} />}
          />
          <Route 
           path="ourTeam"
           element={<PrivateRoute element={<OurTeam />} />}
          />
          <Route 
           path="ourTeam_view"
           element={<PrivateRoute element={<OurTeam_view />} />}
          />
          <Route 
          path="carrer_create"
          element={<PrivateRoute element={<Carrer_create />} />}
          />
          <Route 
          path="carrer_view"
          element={<PrivateRoute element={<Carrer_view />} />}
          />
          <Route 
          path="carrer_update/:id"
          element={<PrivateRoute element={<Carrer_update />} />}
          />
          <Route 
          path="Create_gallery"
          element={<PrivateRoute element={<Create_gallery />} />}
           />
           <Route 
           path="gallery_update/:id"
           element={<PrivateRoute element={<Gallery_update />} />}
           />
           <Route 
           path="gallery"
           element={<PrivateRoute element={<Gallery />}/>}
            />
            <Route
             path="create_single_Service" 
            element={<PrivateRoute element={<Create_single_Service />}/> }
            />
            <Route 
            path="view_single_serviceplan" 
            element={<PrivateRoute  element={<View_single_serviceplan />}/>} 
            /> 
            <Route 
            path="update_singel_Serviceplan/:id"
            element={<PrivateRoute element={<Update_singel_Serviceplan />} />}
            />
           <Route 
           path="create_plan"
           element={<PrivateRoute element= {<Create_plan />} /> }
           />
           <Route 
           path="update_seo/:id"
           element={<PrivateRoute element= {<Update_seo/>} /> }
           />
           <Route 
           path="view_plans"
           element={<PrivateRoute element= {<View_plans />} /> }
           />
          <Route
            path="testimunial" 
            element={<PrivateRoute element={<Testimunial />} />}
          />
          <Route
            path="post"
            element={<PrivateRoute element={<Post_Blog />} />}
          />
          <Route
            path="menu"
            element={<PrivateRoute element={<Menu />} />}
          />
          <Route
            path="sub-menu"
            element={<PrivateRoute element={<Sub_menu />} />}
          />
          <Route
          path="/Create_service"
          element={<PrivateRoute element={<Create_service />} />}
          />
          <Route
          path="/Update_services"
          element={<PrivateRoute element={<Update_services />} />}
          />
          <Route
          path="/view_services"
          element={<PrivateRoute element={<View_services />} />}
          />
          <Route
            path="catagory-view"
            element={<PrivateRoute element={<Catogaryview />} />}
          />
          <Route
            path="projectview"
            element={<PrivateRoute element={<Projectview />} />}
          />
          <Route
            path="postview"
            element={<PrivateRoute element={<Postview />} />}
          />
          <Route
            path="menu-view"
            element={<PrivateRoute element={<Menuview />} />}
          />
          <Route
            path="contact-view"
            element={<PrivateRoute element={<ContactView />} />}
          />
          <Route 
          path="/job_enquiry"
          element={<PrivateRoute element={<Job_enquiry />} />} 
          />
          <Route 
          path="/internship_enquiry"
          element={<PrivateRoute element={<Internship_enquiry />} />}
          />
          <Route
            path="projectlead-view"
            element={<PrivateRoute element={<Project_lead_view />} />}
          />
          <Route 
          path="/project_quaotaions"
          element={<PrivateRoute element={<Project_quaotaions />} />}
          />
          <Route 
          path="/news_letter"
          element={<PrivateRoute element={<News_letter />}/>} 
          />
          <Route
            path="/testimunial-view"
            element={<PrivateRoute element={<Testimunialview />} />}
          />
          <Route
            path="/submenu-view"
            element={<PrivateRoute element={<Submenuview />} />}
          />
          <Route
            path="/catagory-update/:id"
            element={<PrivateRoute element={<CategoryUpdate />} />}
          />
          <Route
            path="/job-update/:id"
            element={<PrivateRoute element={<JobUpdate />} />}
          />
          <Route
            path="/post-update/:id"
            element={<PrivateRoute element={<PostUpdate />} />}
          />
          <Route
            path="/testimony-update/:id"
            element={<PrivateRoute element={<TestimunialUpdate />} />}
          />
          <Route
            path="/project-update/:id"
            element={<PrivateRoute element={<ProjectUpdate />} />}
          />
          <Route
            path="/submenu-update/:id"
            element={<PrivateRoute element={<Submenu_update />} />}
          />
          <Route
            path="/menu-update/:id"
            element={<PrivateRoute element={<MenuUpdate />} />}
          />
          <Route
            path="/website" index
            element={<PrivateRoute element={<CM1 />} />} />

          <Route
            path="/website/graphics" element={<PrivateRoute element={<CM2 />} />} />

          <Route
            path="/website/graphics/editing" element={<PrivateRoute element={<CM3 />} />} />

          <Route
            path="/website/graphics/editing/social" element={<PrivateRoute element={<CM4 />} />} />
       <Route
        path="/google_indexing" element={<PrivateRoute element={<Google_indexing />}/>}
        />
          <Route
            path="/login"
            element={<Login onLogin={() => setLoggedIn(true)} />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
