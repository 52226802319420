import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Table, Thead, Tbody, Th, Td, Tr } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import Reactpaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom';
import SidePanel from '../Admin-Dashbord/SidePanel';
import TokenApi from '../Auth/TokenApi';
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import Dheader from '../Admin-Dashbord/header';

const Catogaryview = () => {

    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [pageNumber, setPageNumber] = useState(0);
    const userPerPage = 10;
    const navigate = useNavigate();
    // send Headers using Context Api 
    const globaldata = useContext(TokenApi)
    const header = (globaldata.headers)
    useEffect(() => {
        axios.get('https://www.sdmipl.com/portal/api/category-view', { header })
            .then((res) => {
                setData(res.data.records);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            })
    }, []);

    // Delete Funtion for delete title  

    const handleDelete = async (id) => {
        const isConfirmed = window.confirm("Are you sure you want to delete this item?");
        if (!isConfirmed) {
            return;
        }
        const FormData = { id: id }
        try {
            const response = await axios.post('https://www.sdmipl.com/portal/api/category-delete', FormData)
            if (response.data.error === true) {
                navigate('/catagory-view');
                alert(response.data.message);
            } else {
                alert(response.data.message)
            }
        }
        catch (err) {
            console.log(err)
        }
    };
    //  pagination =
    const pageCount = Math.ceil(data.length / userPerPage);
    const offset = pageNumber * userPerPage;

    return (

        <div className='d-flex'>
            <SidePanel />
            < section className="right-dash">
                <Dheader />
                <section className="intro">
                    <div className="bg-image h-100" style={{ backgroundColor: '#f5f5f5' }}>
                        <div className="mask d-flex align-items-center h-100">
                            <div className="container col-lg-12 text-center">
                                <div className="row justify-content-center">
                                    <div className="card create">
                                        <div className="card-body p-0">
                                            <h3>Our Category</h3>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <input
                                                    className="form-control my-3 w-50 input-custom border-2 border-primary" type="search" placeholder='serach by Title ' value={search} onChange={(e) => setSearch(e.target.value)} />
                                            </div>
                                            <div className="table-responsive table-scroll" data-mdb-perfect-scrollbar="true">

                                                <Table className="table table-striped mb-0 mt-3">
                                                    <Thead style={{ backgroundColor: 'lightBlue' }}>
                                                        <Tr>
                                                            <Th scope="col">Catogary S.No</Th>
                                                            <Th scope="col">Catagory Title</Th>
                                                            <Th scope="col">Action</Th>

                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {data

                                                            .filter((item) =>
                                                                search.toLowerCase() === '' ? true : item.title.toLowerCase().includes(search.toLowerCase())
                                                            )
                                                            .slice(offset, offset + userPerPage)
                                                            .map((item, index) => (
                                                                <Tr key={item.id}>
                                                                    <Td>{index + 1}</Td>
                                                                    <Td>{item.title}</Td>

                                                                    <Td>
                                                                        <Link style={{ textDecoration: 'none', color: "white" }} to={`/catagory-update/${item.id}`}>
                                                                            <button type="button" className="btn btn-success "><span><FaEdit /></span>
                                                                            </button>
                                                                        </Link>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-danger ml-2"
                                                                            onClick={(e) => {
                                                                                handleDelete(item.id);
                                                                            }}
                                                                        ><RiDeleteBin5Fill />

                                                                        </button>
                                                                    </Td>
                                                                </Tr>
                                                            ))}
                                                    </Tbody>
                                                </Table>
                                                {/* pagination */}
                                                <div className='pagination pagination-lg justify-content-center text-center mt-3'>
                                                    <Reactpaginate
                                                        nextLabel={<>Next </>}
                                                        pageCount={pageCount}
                                                        onPageChange={(selectedPage) => setPageNumber(selectedPage.selected)}
                                                        disabledClassName={'pagination__link--disabled'}
                                                        previousLabel="Previous"
                                                        containerClassName='pagination justify-content-center pagination-lg pagination-sm pagination-col'
                                                        breakClassName='page-item'
                                                        breakLinkClassName='page-link'
                                                        previousClassName='page-item'
                                                        previousLinkClassName='page-link'
                                                        nextClassName='page-item'
                                                        nextLinkClassName='page-link'
                                                        activeClassName='active  pb-3'
                                                        pageClassName='page-item ml-1 mr-1'  // Custom class for pagination item
                                                        pageLinkClassName='page-link'  // Custom class for pagination link
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>

        </div>

    )
}

export default Catogaryview