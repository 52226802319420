import React, { useState } from 'react';
import axios from 'axios';
import SidePanel from '../Admin-Dashbord/SidePanel';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import Dheader from '../Admin-Dashbord/header';
const Google_indexing = () => {
    const [title, setTitle] = useState('');
    const FormData = {
        title: title,
    }

    const navigate = useNavigate();

    const handleAdd = async (e) => {
        e.preventDefault();
        try {
            console.log(FormData);
            const response = await axios.post('#', FormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },

            });
            if (response.status === 200) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 4000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    },
                });
                Toast.fire({
                    icon: 'success',
                    title: 'Data inserted Successfully',
                });
                navigate('#');
            } else {
                console.log("Internal server failed");
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
            });
            
            Toast.fire({
                icon: 'error',
                title: 'Error submitting form',
            });
        }
    };
    const handleStatus = async (e) => {
        e.preventDefault();
        try {
            console.log(FormData);
            const response = await axios.post('#', FormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },

            });
            if (response.status === 200) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 4000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    },
                });
                Toast.fire({
                    icon: 'success',
                    title: 'Data inserted Successfully',
                });
                navigate('#');
            } else {
                console.log("Internal server failed");
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
            });
            
            Toast.fire({
                icon: 'error',
                title: 'Error submitting form',
            });
        }
    };
    const handleDelete = async (e) => {
        e.preventDefault();
        try {
            console.log(FormData);
            const response = await axios.post('#', FormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },

            });
            if (response.status === 200) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 4000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    },
                });
                Toast.fire({
                    icon: 'success',
                    title: 'Data inserted Successfully',
                });
                navigate('#');
            } else {
                console.log("Internal server failed");
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
            });
            
            Toast.fire({
                icon: 'error',
                title: 'Error submitting form',
            });
        }
    };
    return (
        <>
            <div className='d-flex'>
                <SidePanel />
                <section className="right-dash">
                    <Dheader />
                    <div className="container mt-2 col-lg-12">
                        <div className='card create'>
                            <h2 className='text-center'> Google Indexing </h2>
                            <div className='p-3'>
                                <form onSubmit={handleAdd} encType='multipart/form-data'>
                                        <label className='d-block font-weight-bold text-success'>Add/Update URL for Google Indexing: </label>
                                        <div className='d-flex align-items-center'>
                                            <input className='form-control' type='text' name='title' placeholder='Add url for Indexing' required />
                                            <div className='btn'>
                                                <input type="submit" className="btn btn-success" value="Add" />
                                            </div>
                                        </div>
                                </form>
                            </div>
                            <div className='p-3'>
                                <form onSubmit={handleStatus} encType='multipart/form-data'>
                                        <label className='d-block font-weight-bold text-warning'>Check Status URL for Google Indexing: </label>
                                        <div className='d-flex align-items-center'>
                                            <input className='form-control' type='text' name='title' placeholder='Check Status URL for Indexing' required />
                                            <div className='btn'>
                                                <input type="submit" className="btn btn-warning"  value="Status" />
                                            </div>
                                        </div>
                                </form>
                            </div>
                            <div className='p-3'>
                                <form onSubmit={handleDelete} encType='multipart/form-data'>
                                        <label className='d-block font-weight-bold text-danger'>Delete URL for Google Indexing :</label>
                                        <div className='d-flex align-items-center'>
                                            <input className='form-control' type='text' name='title' placeholder='Delete url from Indexing' required />
                                            <div className='btn'>
                                                <input type="submit" className="btn btn-danger" value="Delete" />
                                            </div>
                                        </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Google_indexing;