import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SidePanel from '../Admin-Dashbord/SidePanel';
import Swal from 'sweetalert2';
const ProjectUpdate = () => {
  const { id } = useParams();
  const [FormData, setFormData] = useState({
    id: '',
    title: '',
    url: '',
    image: '',
    description: ''
  });
  const navigate = useNavigate()
  useEffect(() => {
    axios
      .get(`https://www.sdmipl.com/portal/api/portfolio_single_record/${id}`)
      .then((res) => {
       // console.log(res);
        const postData = res.data.records;
        setFormData({
          id: postData.id,
          title: postData.title,
          description: postData.description,
          url: postData.url,
          image: postData.image,
        });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://www.sdmipl.com/portal/api/portfolio-update', FormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    //  console.log(response)
      if (response.status === 200) {
        navigate('/projectview');
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: 'success',
          title: response.data.message,
        });
      } else {
        navigate('/projectview');
        alert("Internal server failed");
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert("Error submitting form. Please try again.");
    }
  };


  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === 'file') {
      setFormData({
        ...FormData,
        [name]: e.target.files[0],
      });
    } else {
      setFormData({
        ...FormData,
        [name]: value,
      });
    }
  };
  const style = {
    display: 'flex',
    justifyContent: 'space-between'
  };

 
      // logout Functionality
      const handleLogout = () => {
        const token = localStorage.getItem('token');
        const  headers = {
             'Authorization': `Bearer ${token}`
         }
       if (token) {
           axios.post('https://www.sdmipl.com/portal/api/logout', { headers })
               .then((res) => {
                   alert(res.data.message);
                   localStorage.removeItem('token');
                   navigate('/login');
               })
               .catch((error) => {
                   console.error('Error logging out:', error);
               });
       } else {
   
           alert('No token found in local storage. User may not be logged in');
       }
   };

  return (
    <>
      <div className='seprate' style={style}>
        <SidePanel />
        < section className="right-dash">
          <div id="right-panel" className="right-panel">
          <header id="header" className="header">
              <div className="header-menu">
                <div className="col-sm-10">

                  <div className="page-header ">
                    <div className="page-title">
                      <h1>Sion Datamatics india Pvt Ltd</h1>
                    </div>
                  </div>

                </div>
                <div className="col-sm-2 items-right  custom1">
                  <button className="nav-link cursor-pointer" onClick={handleLogout}><i className="fa fa-power-off text-danger" /> logout </button>
                  <div className="user-menu dropdown-menu">
                  </div>
                </div>
              </div>
            </header>
          
          </div>
          <section className="container col-lg-8">
            <h2 className='text-center mt-5'>Update Project`s</h2>
            <div className="form-group">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="title">Project Title :</label>
                  <input
                    type="text"
                    onChange={handleChange}
                    className="form-control"
                    id="title"
                    name="title"
                    placeholder="Enter your Project Title"
                    value={FormData.title}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="url">Project - URL</label>
                  <input
                    type="text"
                    className="form-control"
                    id="url"
                    name="url"
                    placeholder="Enter your Project - URL"
                    onChange={handleChange}
                    value={FormData.url}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="description">Project - Description</label>
                  <textarea
                    className="form-control"
                    onChange={handleChange}
                    id="description"
                    name="description"
                    placeholder="Enter your Project - Description"
                    value={FormData.description}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="image">Project - image</label>
                  <input type='file'
                    className="form-control"
                    onChange={handleChange}
                    name="image"
                    placeholder="Enter your Project - image"

                  />
                </div>
                <div className="btn">
                  <input type="submit" className="btn btn-warning" value="Submit" />
                </div>
              </form>
            </div>
          </section>
        </section>

      </div>
    </>
  )
};

export default ProjectUpdate;