// ========================================  {  Catagory Title }   ===========================
import axios from 'axios';
import React, { useState } from 'react'
import SidePanel from '../Admin-Dashbord/SidePanel';
import { useNavigate } from 'react-router';
import Dheader from '../Admin-Dashbord/header';
const Catagory = () => {
  const [catagoryTitle, setCatagoryTitle] = useState('');
  const navigate = useNavigate()
  const FormData = {
    title: catagoryTitle
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('https://www.sdmipl.com/portal/api/category-create', FormData)
      .then((res) => {
        if (res.data.error === true) {
          alert(res.data.message);
          navigate('/catagory-view')
        } else {
          alert(res.data.message);
        }

      })
      .catch((error) => {
        console.error('Error submitting form:', error);
      });
  };

  return (
    <div className='d-flex'>
      <SidePanel />
      < section className="right-dash">
      <Dheader />
        <div className="container col-lg-12">
          <div className='card create'>
          <h2 className='text-center'> Create Category </h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="catagoryTitle"> Category :</label>
              <input type="text" className="form-control" id="catagoryTitle" onChange={(e) => setCatagoryTitle(e.target.value)} name="catagoryTitle" placeholder="Enter your Category Title" />
            </div>
            <div className='btn'><input type="submit" className="btn btn-warning" value="Submit" /></div>
          </form>
          </div>
        </div>
      </section>

    </div>

  )
}

export default Catagory; 