import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import SidePanel from '../Admin-Dashbord/SidePanel';
import Swal from 'sweetalert2';
import Dheader from '../Admin-Dashbord/header';

const PostUpdate = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState({
        id: '',
        slug: '',
        title: '',
        category_id: '',
        content: '',
        image: null,
        meta_title: '',  // Changed from null to empty string
        meta_keyword: '',  // Changed from null to empty string
        meta_description: '',  // Changed from null to empty string
    });
    const [postTitles, setPostTitles] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const navigate = useNavigate();

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
        setFormData({
            ...formData,
            image: file,
        });
    };

    useEffect(() => {
        axios.get(`https://www.sdmipl.com/portal/api/post_single_record/${id}`)
            .then((res) => {
                const postData = res.data.records;
                setFormData({
                    id: postData.id,
                    slug: postData.slug,
                    title: postData.title,
                    category_id: parseInt(postData.category_id),
                    content: postData.content,
                    image: null,
                    meta_title: postData.meta_title || '',  // Changed from null to empty string
                    meta_keyword: postData.meta_keyword || '',  // Changed from null to empty string
                    meta_description: postData.meta_description || '',  // Changed from null to empty string
                });
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });

        const apiUrl = 'https://www.sdmipl.com/portal/api/category-view';
        axios.get(apiUrl)
            .then((response) => {
                setPostTitles(response.data.records);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });

    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const token = localStorage.getItem('token');
            const headers = {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            };

            const updatedFormData = new FormData();
            Object.keys(formData).forEach((key) => {
                updatedFormData.append(key, formData[key]);
            });
            updatedFormData.append('image', selectedImage);

            const res = await axios.post(`https://www.sdmipl.com/portal/api/post-update`, updatedFormData, { headers });

            if (res.status === 200) {
                navigate('/postview');
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 4000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    },
                });
                Toast.fire({
                    icon: 'success',
                    title: res.data.message,
                });
            } else {
                alert(res.data.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const titleCount = formData.meta_title.length;
    let titlecolorClass = '';

    if (titleCount > 0 && titleCount <= 69) {
        titlecolorClass = 'text-success';
    } else {
        titlecolorClass = 'text-danger';
    }

    const keywordCount = formData.meta_keyword.length;  // Should not be null due to || '' in useEffect
    let keywordcolorClass = '';

    if (keywordCount >= 10) {
        keywordcolorClass = 'text-success';
    } else {
        keywordcolorClass = 'text-danger';
    }

    const descriptionCount = formData.meta_description.length;  // Should not be null due to || '' in useEffect
    let descriptionColorClass = '';

    if (descriptionCount > 0 && descriptionCount < 170) {
        descriptionColorClass = 'text-success';
    } else {
        descriptionColorClass = 'text-danger';
    }

    const handleLogout = () => {
        const token = localStorage.getItem('token');
        if (token) {
            axios.post('https://www.sdmipl.com/portal/api/logout', {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((res) => {
                    alert(res.data.message);
                    localStorage.removeItem('token');
                    navigate('/login');
                })
                .catch((error) => {
                    console.error('Error logging out:', error);
                });
        } else {
            alert('No token found in local storage. User may not be logged in');
        }
    };

    return (
        <>
            <div className='d-flex'>
                <SidePanel />
                <section className="right-dash">
                    <Dheader />
                    <section className="container col-lg-12">
                        <div className='card create' >
                            <h2>Update Post Content / Post / Blog</h2>
                            <div className="form-group">
                                <form onSubmit={handleSubmit}>
                                    <div className='form-group text-center'>
                                        <label htmlFor="category-title" className="mr-3">Category select :</label>
                                        <select className='text-center p-1 rounded' name="category_id" id="category_id" onChange={handleChange} value={formData.category_id}>
                                            {postTitles && postTitles.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.title}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="slug">Slug:</label>
                                        <input
                                            type='text'
                                            className="form-control"
                                            id="slug"
                                            name="slug"
                                            placeholder="Enter your slug"
                                            onChange={handleChange}
                                            value={formData.slug}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="post-title">Post update:</label>
                                        <input
                                            type='text'
                                            className="form-control"
                                            id="post-title"
                                            name="title"
                                            placeholder="Enter your Post Title"
                                            onChange={handleChange}
                                            value={formData.title}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Post-Content">Post Content:</label>
                                        <textarea
                                            className="form-control"
                                            id="Post-Content"
                                            name="content"
                                            placeholder="Enter your Post Content"
                                            onChange={handleChange}
                                            value={formData.content}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Post-image">Post image:</label>
                                        <input type="file" className="form-control" id="image" name="image" onChange={handleImageChange} />
                                        {selectedImage && (
                                            <div>
                                                <p>Selected file: {selectedImage.name}</p>
                                                <img src={URL.createObjectURL(selectedImage)} alt="Selected-item" width="100" />
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="meta_title">Meta - Title: maximum 70 Characters</label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            id="meta_title"
                                            name="meta_title"
                                            placeholder="Enter your Meta Tags"
                                            value={formData.meta_title}
                                            onChange={handleChange}
                                        />
                                        <span className={titlecolorClass}>{formData.meta_title.length}/70 characters maximum</span>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="meta_keyword">Meta - Keyword: minimum 10 keywords or Above</label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            id="meta_keyword"
                                            name="meta_keyword"
                                            placeholder="Enter your Meta Tags"
                                            value={formData.meta_keyword}
                                            onChange={handleChange}
                                            minLength={10}
                                        />
                                        <span className={keywordcolorClass}>{formData.meta_keyword.length}/Minimum 10 characters</span>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="meta_description">Meta - Description: maximum 170 Characters</label>
                                        <textarea
                                            className="form-control"
                                            id="meta_description"
                                            name="meta_description"
                                            placeholder="Enter your Meta Description"
                                            value={formData.meta_description}
                                            onChange={handleChange}
                                        />
                                        <span className={descriptionColorClass}>{formData.meta_description.length}/170 characters maximum</span>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary">
                                            Update Post
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </section>
            </div>
        </>
    );
};

export default PostUpdate;
