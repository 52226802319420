import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Table, Thead, Tbody, Th, Td, Tr } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import Reactpaginate from 'react-paginate';
import Swal from 'sweetalert2';
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import SidePanel from '../../Admin-Dashbord/SidePanel';
import Dheader from '../../Admin-Dashbord/header';

const Project_quaotaions = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const userPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://www.sdmipl.com/portal/api/get-quotation')
      .then((res) => {
        setData(res.data.result);
        console.log(res.data.result, 'Service Plan');
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // Delete Funtion for delete title  = 
  const handleDelete = async (id) => {
    console.log(id);
    const formData = { id: id };
    try {
      const response = await axios.post(`https://sdmipl.com/portal/api/delete-service-plan/${id}`, formData);
      if (!response.data.error && response.status === 200) {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: 'success',
          title: 'Data Deleted Successfully',
        });
        navigate('/view_single_serviceplan');
      } else {
        console.log('Error:', response.data.message);
        Swal.fire({
          icon: 'error',
          title: 'Failed to delete',
          text: response.data.message,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Pagination 
  const pageCount = Math.ceil(data && data.length / userPerPage);
  const offset = pageNumber * userPerPage;

  return (
    <>
      <div className='d-flex'>
        <SidePanel />
        < section className="right-dash">
          <Dheader />
          <section className="intro">
            <div className="bg-image h-100" style={{ backgroundColor: '#f5f5f5' }}>
              <div className="mask d-flex align-items-center h-100">
                <div className="container col-lg-12 text-center ">
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <div className="card create">
                        <h2 className='fw-bold'>Quatation Plan's</h2>
                        <div className="card-body p-0">
                          <div className="d-flex align-items-center justify-content-center">
                            <input
                              className="form-control my-3 w-50 input-custom border-2 border-primary" type="search" placeholder='Serach by  Title..' value={search} onChange={(e) => setSearch(e.target.value)} />
                          </div>
                          <div className="table-responsive table-scroll" data-mdb-perfect-scrollbar="true">

                            <Table className="table table-striped mb-0 mt-3">
                              <Thead style={{ backgroundColor: 'lightBlue' }}>
                                <Tr>
                                  <Th scope="col">S.NO</Th>
                                  <Th scope="col">Name</Th>
                                  <Th scope="col">Email</Th>
                                  <Th scope='col'>Mobile</Th>
                                  <Th scope='col'>Project Type</Th>
                                  <Th scope="col">Plan Type</Th>
                                  <Th scope="col">Services</Th>
                                  <Th scope="col">Service Charge</Th>
                                  <Th scope="col">Project Description</Th>
                                  <Th scope="col">Total Pages</Th>
                                  <Th scope="col">Targeted Location</Th>
                                  <Th scope="col">Domain Required</Th>
                                  <Th scope="col">Monthly Budget</Th>
                                  <Th scope="col">Suggested Budget</Th>
                                  <Th scope="col">Website url</Th>
                                  <Th scope="col">Demo Image</Th>
                                  <Th scope="col">Action</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {data && data
                                  .filter((item) =>
                                    search.toLowerCase() === '' ? true : item.service_title.toLowerCase().includes(search.toLowerCase())
                                  )
                                  .slice(offset, offset + userPerPage)
                                  .map((item, index) => (
                                    <Tr className="vertical-align-middle" key={item.id}>
                                      <Td>{index + 1}</Td>
                                      <Td>{item.name}</Td>
                                      <Td>{item.email}</Td>
                                      <Td>{item.mobile}</Td>
                                      <Td>{item.project_type}</Td>
                                      <Td>{item.plan_type}</Td>
                                      <Td>{item.services}</Td>
                                      <Td>{item.service_charge}</Td>
                                      <Td>{item.project_description}</Td>
                                      <Td>{item.project_pages}</Td>
                                      <Td>{item.targeted_location}</Td>
                                      <Td>{item.domain_required}</Td>
                                      <Td>{item.monthly_budget}</Td>
                                      <Td>{item.suggested_budget}</Td>
                                      <Td>{item.website_url}</Td>
                                      <Td>
                                        <img src={item.file_upload_demo} alt="Project-template" width="100" height="100" />
                                      </Td>
                                      <Td className='d-flex'>
                                        <Link style={{ textDecoration: 'none', color: "white" }} to={`/update_singel_Serviceplan/${item.id}`}> <button type="button" className="btn btn-success "><span><FaEdit /></span></button></Link>

                                        <button
                                          type="button"
                                          className="btn btn-danger  ml-2"
                                          onClick={(e) => { handleDelete(item.id) }}><span><RiDeleteBin5Fill /></span>
                                        </button>
                                      </Td>

                                    </Tr>
                                  ))}
                              </Tbody>
                            </Table>

                            {/* pagination */}
                            <div className='pagination pagination-lg justify-content-center text-center mt-3'>
                              <Reactpaginate
                                nextLabel={<>Next </>}
                                pageCount={pageCount}
                                onPageChange={(selectedPage) => setPageNumber(selectedPage.selected)}
                                disabledClassName={'pagination__link--disabled'}
                                previousLabel="Previous"
                                containerClassName='pagination justify-content-center pagination-lg pagination-sm pagination-col'
                                breakClassName='page-item'
                                breakLinkClassName='page-link'
                                previousClassName='page-item'
                                previousLinkClassName='page-link'
                                nextClassName='page-item'
                                nextLinkClassName='page-link'
                                activeClassName='active  pb-3'
                                pageClassName='page-item ml-1 mr-1'  // Custom class for pagination item
                                pageLinkClassName='page-link'  // Custom class for pagination link
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
    </>

  )

}

export default Project_quaotaions;