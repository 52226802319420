import React, { useState, useEffect, useRef } from 'react'
import JoditEditor from 'jodit-react';
import "./calculator.css"
import { Link } from 'react-router-dom';

const CM4 = () => {
    const editor = useRef(null);
    const [content, setContent] = useState('');
    console.log("ritchtextEditor", content)
    const [images, setImages] = useState([]);
    const [file, setFile] = useState(null);
    console.log(images, file);

    const handleImageChange = (e) => {
        const files = e.target.files;

        const totalSize = Array.from(files).reduce((acc, file) => acc + file.size, 0);
        const maxSize = 10 * 1024 * 1024; // 10 MB

        if (totalSize > maxSize) {
            alert('Total file size exceeds 10 MB. Please select fewer or smaller files.');
            return;
        }

        if (images.length + files.length > 3) {
            alert('You can upload a maximum of 3 images.');
            return;
        }

        const newImages = Array.from(files).map((file) => ({
            file,
            preview: URL.createObjectURL(file),
        }));

        setImages((prevImages) => [...prevImages, ...newImages]);
    };

    const handleRemoveImage = (index) => {
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
    };
    // upload pdf to backend 

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];

        if (selectedFile && selectedFile.type === 'application/pdf') {
            // Read the file and convert it to a data URL
            const reader = new FileReader();
            reader.onloadend = () => {
                setFile(selectedFile);

            };
            reader.readAsDataURL(selectedFile);
        } else {
            // Handle invalid file type
            alert('Please select a valid PDF file.');
            setFile(null);
        }
    };


    //  Start Editing Section
    const [checkedItem1, setCheckedItem1] = useState(0);
    const [checkedItem2, setCheckedItem2] = useState(0);
    const [checkedItem3, setCheckedItem3] = useState(0);
    const [checkedItem4, setCheckedItem4] = useState(0);

    const [totalsocial, settotalsocial] = useState(1)
    const [socialtotal, setsocialtotal] = useState(0.00);
    const [socialtax, Setsocialtax] = useState()
    const [stt, setstt] = useState()
    const [plan, setplan] = useState(0);

    const handleplan = (e) => {
        setplan(e.target.value);

    };

    useEffect(() => {
        const social = parseInt(checkedItem1) + parseInt(checkedItem2) + parseInt(checkedItem3) + parseInt(checkedItem4);
        settotalsocial(social);
        const sociall = social * plan;
        setsocialtotal(sociall);
        const stax = parseInt(sociall * 0.18);
        Setsocialtax(stax)
        setstt(parseInt(sociall + stax));


        //setWarningMessage(warning);
    }, [checkedItem1, checkedItem2, checkedItem3, checkedItem4, plan]);


    return (
        <>

            <section className="main-heading">
                <div className="container">
                    <div className="Header-Links ">
                        <ul className='d-flex justify-content-between'>
                            <li><Link to="/website" >Website Budget Calculator</Link> </li>
                            <li><Link to="/website/graphics" >Graphic Budget Calculator</Link> </li>
                            <li><Link to="/website/graphics/editing" >Editing Budget Calculator</Link> </li>
                            <li><Link to="/website/graphics/editing/social" >Social Media Budget Calculator</Link> </li>
                        </ul>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="text-center mt-5">Social Media Budget Calculator</h1>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container-fluid budget pb-5">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="services mt-5 ">
                            <div className="one p-3">

                                <div className="row">
                                    <div className="col-lg-5 col pl-0 p-2">
                                        <div className="dropd">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input mt-2"
                                                    type="checkbox"
                                                    name="group0"
                                                    id="exampleCheckbox1"
                                                    checked={checkedItem1 === 1}
                                                    onChange={(e) => setCheckedItem1(e.target.checked ? 1 : 0)}
                                                />
                                                <label className="form-check-label" htmlFor="exampleCheckbox1">
                                                    <span className="hh6 text-center">facebook</span>
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input mt-2"
                                                    type="checkbox"
                                                    name="group0"
                                                    id="exampleCheckbox2"
                                                    checked={checkedItem2 === 1}
                                                    onChange={(e) => setCheckedItem2(e.target.checked ? 1 : 0)}
                                                />
                                                <label className="form-check-label" htmlFor="exampleCheckbox2">
                                                    <span className="hh66">Twitter x </span>
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input mt-2"
                                                    type="checkbox"
                                                    name="group0"
                                                    id="exampleCheckbox3"
                                                    checked={checkedItem3 === 1}
                                                    onChange={(e) => setCheckedItem3(e.target.checked ? 1 : 0)}
                                                />
                                                <label className="form-check-label" htmlFor="exampleCheckbox3">
                                                    <span className="hh6">Youtube</span>
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input mt-2"
                                                    type="checkbox"
                                                    name="group0"
                                                    id="exampleCheckbox4"
                                                    checked={checkedItem4 === 1}
                                                    onChange={(e) => setCheckedItem4(e.target.checked ? 1 : 0)}
                                                />
                                                <label className="form-check-label" htmlFor="exampleCheckbox4">
                                                    <span className="hh66"> Instgram </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-7 col d-flex justify-content-center align-items-center">
                                        <div className="dropdown mt-5" style={{ margin: '23px' }}>
                                            <select
                                                className="form-control special mr-5 text-center"
                                                value={plan}
                                                onChange={handleplan} style={{ padding: '8px' }}
                                            >
                                                <option value="">Select a Plan</option>
                                                <option value="1000">Weekly Plan</option>
                                                <option value="4000">Monthly Plan</option>
                                                <option value="12000">Quaterly Plan</option>
                                            </select>

                                        </div>

                                    </div>

                                    <div className="col-lg-12 m-0 p-0">
                                        <ul>
                                            <li><strong>Weekly Plan (7 Days) : </strong> In a weekly plan we offer  5 Post  , 2 videos And 3 content post in  100 Words.</li>
                                            <li><strong>Monthly Plan (28 Days) :</strong> In a Monthly plan we offer  22 Post  , 8 videos And 12  content post in  100 Words.</li>
                                            <li><strong>Quaterly Plan (84 Days) :</strong> In a Quaterly plan we offer  70 Post  , 25 videos And 40  content post in  100 Words.</li>

                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="services mt-5">
                            <div class="one">
                                <div class="row ">
                                    <h2 class="mt-5 text-center">Documentation</h2>

                                    <div class="col-lg-6  col-sm-6 mb-5 px-5">


                                        <div className="form-group mt-5">
                                            <label htmlFor="exampleFormControlFile1" className="doc mb-5">
                                                Upload your logo(naximum 3 Images)
                                            </label>
                                            <input
                                                type="file"
                                                className="form-control-file"
                                                id="exampleFormControlFile1"
                                                accept="png, jpeg, jpg"
                                                onChange={handleImageChange}
                                                multiple
                                            />
                                        </div>
                                        <div className="image-row">
                                            {images.map((image, index) => (
                                                <div key={index} className="image-preview-container">
                                                    <img src={image.preview} alt={`Preview ${index}`} className="image-preview" />
                                                    <span onClick={() => handleRemoveImage(index)} className="fa fa-trash text-danger ml-1">
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-sm-6 mb-5 px-5">
                                        <div className="form-group mt-5">
                                            <label htmlFor="exampleFormControlFile1" className="doc mb-5">
                                                Upload your Document
                                            </label>
                                            <input
                                                type="file"
                                                className="form-control-file"
                                                id="exampleFormControlFile1"
                                                accept="application/pdf"
                                                onChange={handleFileChange}
                                            />
                                        </div>


                                    </div>
                                    {/*   text editor */}
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <JoditEditor
                                                    ref={editor}
                                                    value={content}
                                                    //config={config}
                                                    tabIndex={1} // tabIndex of textarea
                                                    onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                                    onChange={newContent => setContent(newContent)}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="second-side mt-5 ">
                            <div className="inner">
                                <h4 className="head "> Order Summary</h4>
                                <div className="total">
                                    <div className="inr-total d-flex justify-content-space-around my-auto">
                                        <h6>Total Amount</h6>
                                        <p>
                                            <span className='mr-2' id="pages">{totalsocial}</span>
                                            <i className="fa-solid fa-xmark text-dark" />
                                            <span className='ml-3' id="fix">{plan}</span>
                                        </p>
                                        <p>
                                            <i className="fa-solid fa-indian-rupee-sign" />
                                            <span id="result">{socialtotal}</span          >
                                        </p>
                                    </div>

                                    <div className=" inr-total  d-flex justify-content-space-around my-auto">
                                        <span ><h6 className='d-flex'>Taxes <span> (18%) </span></h6></span>
                                        <p>
                                            <i className="fa-solid fa-indian-rupee-sign" />
                                            <span id="tax">{socialtax}</span>
                                        </p>
                                    </div>
                                    <div className=" inr-total grand d-flex justify-content-space-around my-auto">
                                        <h5>Grand Total</h5>
                                        <p>
                                            <i className="fa-solid fa-indian-rupee-sign" />
                                            <span id="gt">{stt}</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="check">
                                    <div className="input-group mb-3">
                                        <div className="form-check">

                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="btn btnn" id="submit">
                                    Submit
                                </button>
                                <button type="button" className="btn btn-primary">
                                    <Link to="https://www.sdmipl.com/" className="text-light" style={{ textDecoration: 'none' }}>Go to the HomePage</Link>
                                </button>
                            </div>
                        </div>
                    </div>





                </div>
            </div>

        </>
    )
}

export default CM4






