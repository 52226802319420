// =========================================    {  Testimonials  }     ==========================
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import SidePanel from '../Admin-Dashbord/SidePanel';
import Dheader from '../Admin-Dashbord/header';
import Swal from 'sweetalert2';

const TestimunialUpdate = () => {
  const { id } = useParams();
  const [FormData, setFormData] = useState({
    id: '',
    name: '',
    testimony: '',
    designation: '',
    image: '',
  });

  const navigate = useNavigate()
  useEffect(() => {
    axios.get(`https://www.sdmipl.com/portal/api/testimonial_single_record/${id}`)
      .then((res) => {
        const postData = res.data.records;
        setFormData({
          id: postData.id,
          name: postData.name,
          testimony: postData.testimony,
          designation: postData.designation,
        });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [id]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://www.sdmipl.com/portal/api/testimonial-update', FormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      //   console.log("dsd",response)
      //   console.warn(FormData)
      if (response.status === 200) {
        navigate('/testimunial-view')
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: 'success',
          title: 'record has been updated ',
        });
      } else {
        navigate('/testimunial-view');
        alert("Internal server failed");
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert("Error submitting form. Please try again.");
    }
  };
  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === 'file') {
      setFormData({
        ...FormData,
        [name]: e.target.files[0],
      });
    } else {
      setFormData({
        ...FormData,
        [name]: value,
      });
    }
  };

  return (
    <>
      <div className='d-flex'>
        <SidePanel />
        < section className="right-dash">
          <Dheader />
          <div className="container mt-3 col-lg-12">
            <div className='card create'>
              <h2 className='text-center'> Testimonials Update</h2>
              <div className="form-group ">
                <form onSubmit={handleSubmit} encType='multiply'>
                  <div className="form-group">
                    <label htmlFor="name"> Name : </label>
                    <input type="text" className="form-control" id="name" name="name" placeholder="Enter your Name" onChange={handleChange} value={FormData.name} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="testimony">Testimony : </label>
                    <textarea className="form-control text-justify" id="testimony" name="testimony" placeholder="Enter your Testimony" onChange={handleChange} value={FormData.testimony} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="designation"> Designation :  </label>
                    <input type="text" className="form-control" id="designation"
                      name="designation"
                      placeholder="Enter your Designation "
                      onChange={handleChange}
                      value={FormData.designation}
                    />
                  </div>
                  <div className="form-group my-4">
                    <label htmlFor="image">testimonial - image</label>
                    <input type='file'
                      className=" form-control-file"
                      onChange={handleChange}
                      name="image"
                      placeholder="Enter your testimonial - image"
                    />
                  </div>
                  <div className='btn'><input type="submit" className="btn btn-warning" value="Submit" /></div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>

  )

}

export default TestimunialUpdate;