import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import SidePanel from '../Admin-Dashbord/SidePanel';
import { Table, Thead, Tbody, Th, Td, Tr } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import Reactpaginate from 'react-paginate';
import Swal from 'sweetalert2';
import Dheader from '../Admin-Dashbord/header';

const OurTeam_view = () => {
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [pageNumber, setPageNumber] = useState(0);
    const userPerPage = 10;
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('https://sdmipl.com/portal/api/team-member-view')
            .then((res) => {
                setData(res.data);
                console.log(res, 'employee records');
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    // Delete Funtion for delete title  = 

    const handleDelete = async (id) => {
        console.log(id)
        const FormData = { id: id }
        try {
            const response = await axios.post('https://www.sdmipl.com/portal/api/job-delete', FormData)
            if (response.data.error === true) {
                navigate('/ourTeam_view');
                if (response.status === 200) {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 4000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer);
                            toast.addEventListener('mouseleave', Swal.resumeTimer);
                        },
                    });
                    Toast.fire({
                        icon: 'success',
                        title: 'Data Deleted Successfully',
                    });
                    navigate('/ourTeam_view');
                } else {
                    alert("Internal server failed");
                }
            } else {
                alert(response.data.message)
            }
        }
        catch (err) {
            console.log(err)
        }
    };

    // Pagination 
    const pageCount = Math.ceil(data.length / userPerPage);
    const offset = pageNumber * userPerPage;

    return (
        <>
            <div className='d-flex'>
                <SidePanel />
                < section className="right-dash">
<Dheader />
                    <section className="intro">
                        <div className="bg-image h-100" style={{ backgroundColor: '#f5f5f5' }}>
                            <div className="mask d-flex align-items-center h-100">
                                <div className="container col-lg-12 text-center ">
                                    <div className="row justify-content-center">
                                        <div className="col-12">
                                            <div className="card create">
                                                <div className="card-body p-0">
                                                    <h2 className='text-center fw-bold'>Our Team</h2>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <input
                                                            className="form-control my-3 w-50 input-custom border-2 border-primary" type="search" placeholder='Serach by Employee Name..' value={search} onChange={(e) => setSearch(e.target.value)} />
                                                    </div>
                                                    <div className="table-responsive table-scroll" data-mdb-perfect-scrollbar="true">

                                                        <Table className="table table-striped mb-0 mt-3">
                                                            <Thead style={{ backgroundColor: 'lightBlue' }}>
                                                                <Tr>
                                                                    <Th scope="col">S.NO</Th>
                                                                    <Th scope="col">Employee Name</Th>
                                                                    <Th scope="col">Deignation</Th>
                                                                    <Th scope="col">Image</Th>
                                                                    {/* <Th scope="col">Action</Th> */}
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {data && data
                                                                    .filter((item) =>
                                                                        search.toLowerCase() === '' ? true : item.title.toLowerCase().includes(search.toLowerCase())
                                                                    )
                                                                    .slice(offset, offset + userPerPage)
                                                                    .map((item, index) => (
                                                                        <Tr className="vertical-align-middle" key={item.id}>
                                                                            <Td>{index + 1}</Td>
                                                                            <Td>{item.name}</Td>
                                                                            <Td>{item.designation}</Td>
                                                                            <Td>
                                                                                <img src={item.profile_image} alt="Project-template" width="100" height="100" />
                                                                            </Td>

                                                                            {/* <Td className=''>

                                                                                <Link style={{ textDecoration: 'none', color: "white" }} to={`/job-update/${item.id}`}> <button type="button" className="btn btn-success fa fa-edit"></button></Link>

                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-danger fa fa-trash  ml-2"
                                                                                    onClick={(e) => { handleDelete(item.id) }}>
                                                                                </button>
                                                                            </Td> */}

                                                                        </Tr>
                                                                    ))}
                                                        </Tbody>
                                                        </Table>
                                                 
                                                        {/* pagination */}
                                                        <div className='pagination pagination-lg justify-content-center text-center mt-3'>
                                                            <Reactpaginate
                                                                nextLabel={<>Next </>}
                                                                pageCount={pageCount}
                                                                onPageChange={(selectedPage) => setPageNumber(selectedPage.selected)}
                                                                disabledClassName={'pagination__link--disabled'}
                                                                previousLabel="Previous"
                                                                containerClassName='pagination justify-content-center pagination-lg pagination-sm pagination-col'
                                                                breakClassName='page-item'
                                                                breakLinkClassName='page-link'
                                                                previousClassName='page-item'
                                                                previousLinkClassName='page-link'
                                                                nextClassName='page-item'
                                                                nextLinkClassName='page-link'
                                                                activeClassName='active  pb-3'
                                                                pageClassName='page-item ml-1 mr-1'  // Custom class for pagination item
                                                                pageLinkClassName='page-link'  // Custom class for pagination link
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

            </div>
        </>

    )

}

export default OurTeam_view;
