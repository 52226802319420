import React from 'react';
import SidePanel from './SidePanel';
import Centerpanel from './Centerpanel';
import { Outlet } from 'react-router-dom';

const Admin = () => {

  return (
    <>
      <div className='seprate d-flex'>
        <SidePanel />
        <Centerpanel />
        <Outlet />
      </div>

    </>
  )
}
export default Admin;