import React, { useState, useEffect, useRef } from 'react'
import './invoicer.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Invoicer = () => {
    const formRef = useRef()
    const [tableRows, setTableRows] = useState([]);
    const [TotalCount, setTotalCount] = useState(0);
    const [Tax, setTax] = useState(18);
    const [taxinput, settaxinput] = useState(0)
    const [DisCount, setDisCount] = useState(0);
    const [Total, setTotal] = useState(0);
    const [Paid, setPaid] = useState(0);
    const [Balence, setBalence] = useState(0);
    const [startDate, setStartDate] = useState(new Date());


    useEffect(() => {
        // firstly Take Discount 
        const discountedValue = TotalCount - (TotalCount * DisCount / 100);

        // then Take Tax 
        const taxAmount = (discountedValue * taxinput) / 100;
        setTax(taxAmount);
        const taxebleAmount = discountedValue + taxAmount;

        setTotal(taxebleAmount);
        setBalence(taxebleAmount - Paid);
    }, [TotalCount, DisCount, Tax, Paid, taxinput]);

    //  add row For Table   start Here 
    const addRow = (e) => {
        e.preventDefault();
        const newRow = (
            <Row key={tableRows.length + 1} onDelete={() => handleDeleteRow(tableRows.length + 1)} />
        );
        setTableRows([...tableRows, newRow]);
    };
    // row Delet
    const handleDeleteRow = (rowIndex,) => {
        const updatedRows = tableRows.filter((_, index) => index !== rowIndex - 1);
        setTableRows(updatedRows);
        if (updatedRows.length === 0) {
            // window.location.reload();
            formRef.current.reset();
            setBalence(0);
            setTotalCount(0);
            setTotal(0)
        }
    };
    // const handleDeleteRow = (rowIndex) => {

    const Row = ({ onDelete }) => {
        const [amount, setAmount] = useState(0);
        const [Qty, setQty] = useState(1);
        const rowAmount = amount * Qty;
        setTotalCount(TotalCount + parseInt(rowAmount));


        return (
            <tr style={{ width: '100%' }}>
                <td className='p-0'>
                    <input type="text" className="form-control text-dark" name='description' placeholder="Description Of Service Or Product" />
                </td>
                <td className='p-0'>
                    <input type="number" className="form-control text-center" name='Qty' value={Qty} onChange={(e) => setQty(e.target.value)} />
                </td>
                <td className='p-0'>
                    <input type="number" className="form-control text-center" name='amount' onChange={(e) => setAmount(e.target.value)} />
                </td>
                <td className='p-0'>
                    <div className="input-group">
                        <input type="number" className="form-control text-center" value={rowAmount} readOnly />

                        <div className="input-group-append">
                            <button className="btn btn-sm bg-transparent cursor-pointer delete-button" onClick={onDelete}>
                                <i className="text-none text-danger" aria-hidden="true">x</i>
                            </button>
                        </div>
                    </div>
                </td>


            </tr>
        );
    };

    //======================================================== Image Upload Code ================================
    const [image, setImage] = useState(null);
    const hiddenFileInput = useRef(null);

    //console.log(image)

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const imgname = event.target.files[0].name;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            const img = new Image();
            img.src = reader.result;
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const maxSize = Math.max(img.width, img.height);
                canvas.width = maxSize;
                canvas.height = maxSize;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(
                    img,
                    (maxSize - img.width) / 2,
                    (maxSize - img.height) / 2
                );
                canvas.toBlob(
                    (blob) => {
                        const file = new File([blob], imgname, {
                            type: "image/png",
                            lastModified: Date.now(),
                        });


                        setImage(file);
                    },
                    "image/jpeg",
                    0.8
                );
            };
        };
    };



    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    // ================================================================= =======================
    const handleSubmit = async (e) => {
        e.preventDefault();

        window.print();
    };

    return (

        <div className="container">
            <form onSubmit={handleSubmit} ref={formRef}>
                <div className="card card-responsive mb-5 p-4 mb-2">
                    <div className='row  align-items-center card-header'>
                        <div className=" col text-center">
                            <img src="/sionlogo.png" alt="Company Logo" className="logo" width={100} />
                            <h6 className='text-sm'>Sion DataMatics India Pvt. Ltd.</h6>

                            <p className='Address & contact number'>
                                <address>
                                    11,5, Bano Doctor Compound, Green Park, South Tukoganj, Indore, Madhya Pradesh 452001, Ph :
                                    <a href="tel:+917880136882" style={{ textDecoration: 'none' }}>+91 78801 36882</a>
                                </address>


                            </p>
                        </div>

                    </div>

                    <div className="row mb-2 mt-2">
                        <div className="col-8">
                            <div className='for-img-paste'>
                                {/* image Upload Code Start Here  */}

                                <div className="image-upload-container">
                                    <div className="box-decoration">
                                        <label htmlFor="image-upload-input" className="image-upload-label d-none">
                                            {image ? image.name : "Choose an image"}
                                        </label>
                                        <div onClick={handleClick} style={{ cursor: "pointer" }}>
                                            {image ? (
                                                <img src={URL.createObjectURL(image)} alt="img" className="img-display-after" />
                                            ) : (
                                                <img src="/Dummy.jpg" alt="upload your Logo" className="img-display-before" />
                                            )}

                                            <input
                                                id="image-upload-input"
                                                type="file"
                                                onChange={handleImageChange}
                                                ref={hiddenFileInput}
                                                style={{ display: "none" }}
                                            />
                                        </div>

                                    </div>
                                </div>
                                {/* image Upload Code End Here  */}

                            </div>
                        </div>
                        <div className="col-4">
                            <div className="input-group mb-3">
                                <input type="text" className="form-control blog mr-3 text-center custom-xx-large" name='invoice' aria-label="Username" placeholder='invoice' />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">#</span>
                                <input type="text" className="form-control " aria-label="Username" aria-describedby="basic-addon1" name='invoice-number' />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2 mt-2">
                        <div className="col-lg-6">
                            <div className="input-group mb-3">
                                <input type="text" className="form-control p-4 text-center" placeholder="Who is the invoice from to? (required)" required />
                            </div>
                            <div className="input-group mb-3 text-center">
                                <input type="text" className="form-control blog mr-3" aria-label="Username" />

                            </div>
                            <div className="input-group mb-3">
                                <input type="text" className="form-control  p-4 text-center" aria-label="Username" aria-describedby="basic-addon1" placeholder="Who is this invoice to? (required)" required />

                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="input-group mb-1">
                                <input type="text" className="form-control name-input-top  blog mr-2 text-right" placeholder='Date' />
                                <DatePicker className="form-control" selected={startDate} onChange={(date) => setStartDate(date)} />
                            </div>
                            <div className="input-group mb-1">
                                <input type="text" className="form-control name-input-top blog mr-2 text-right" placeholder='Dew Date' />
                                <DatePicker className="form-control" selected={startDate} onChange={(date) => setStartDate(date)} />
                            </div>
                            <div className="input-group mb-1 ">
                                <input type="text" className="form-control name-input-top blog mr-2 text-right" placeholder='Payment Terms ' />
                                <input type="text" className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <table className="table table-responsive">
                                <thead className="table-dark" style={{ WebkitPrintColorAdjust: 'exact' }}>
                                    <tr className='m-0'>
                                        <th scope="" className='p-0' style={{ width: "50%" }}>
                                            <input
                                                type="text"
                                                className="form-control blog text-center text-white"
                                                placeholder='item
                                                 '
                                                style={{ backgroundColor: '#212529' }}
                                            />
                                        </th>
                                        <th scope="" className='p-0'>
                                            <input
                                                type="text"
                                                className="form-control blog text-center text-white"
                                                placeholder='Qty'
                                                style={{ backgroundColor: '#212529' }}
                                            />
                                        </th>
                                        <th scope="" className='p-0'>
                                            <input
                                                type="text"
                                                className="form-control blog text-center text-white"
                                                placeholder='Rate'
                                                style={{ backgroundColor: '#212529' }}
                                            />
                                        </th>
                                        <th scope="" className='p-0'>
                                            <input
                                                type="text"
                                                className="form-control blog text-center text-white"
                                                placeholder='Amount'
                                                style={{ backgroundColor: '#212529' }}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                {/* add row For Table Data  start Here */}

                                <tbody className='p-name'>
                                    {tableRows.map((row, index) => React.cloneElement(row, { key: index }))}

                                    <button className="btn btn-dark btn-sm mt-1 p-1" onClick={addRow}>
                                        + Add Row
                                    </button>
                                </tbody>

                            </table>
                        </div>
                    </div>

                    <div className="row mb-2 mt-2">
                        <div className="col-lg-6 col-sm-6">
                            <div className="input-group mb-3">
                                <input type="text" className="form-control blog mr-3 " aria-label="Notes" placeholder='Notes' />
                            </div>
                            <div className="input-group mb-3">
                                <textarea
                                    className="form-control p-4"
                                    aria-label="Notes"
                                    placeholder="Notes- Any relevant information not already covered"
                                    aria-describedby="basic-addon1"
                                    style={{ resize: "none" }} // Set the desired width and disable resizing
                                ></textarea>
                            </div>


                            <div className="input-group mb-3">
                                <input type="text" className="form-control blog mr-3 " aria-label="Username" placeholder='Terms' />
                            </div>
                            <div className="input-group mb-3">
                                <textarea className="form-control p-4" aria-label="Username" placeholder="Terms and conditionns- late fees, payment methods, delivery shedule"
                                    aria-describedby="basic-addon1" style={{ resize: "none" }}></textarea>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                            <div className="input-group mb-1">
                                <input type="text" className="form-control blog mr-2 text-right" placeholder='Sub Total' />

                                <input type="text" className="form-control blog text-center" value={TotalCount ? TotalCount : ''} readOnly />
                            </div>

                            <div className="input-group mb-1">
                                <input
                                    type="text"
                                    className="form-control input-txt blog mr-2 text-right"
                                    placeholder='Discount (in %)'
                                />

                                <input
                                    type="number"
                                    className="form-control text-center"
                                    value={DisCount}
                                    onChange={(e) => setDisCount(e.target.value)}
                                />
                            </div>

                            <div className="input-group mb-1">
                                <input type="text" className="form-control input-txt blog mr-2 text-right" placeholder='Tax (Std - 18% )' />

                                <input type="number" className="form-control text-center" value={taxinput} onChange={(e) => settaxinput(e.target.value)} />


                            </div>


                            <div className="input-group mb-1">
                                <input type="text" className="form-control blog mr-2 text-right" placeholder='Total' />
                                <input type="number" className="form-control text-center" value={Total} />
                            </div>
                            <div className="input-group mb-1">
                                <input type="text" className="form-control blog mr-2 text-right" placeholder='Paid Amount' />
                                <input type="number" className="form-control text-center" value={Paid} onChange={(e) => setPaid(e.target.value)} />
                            </div>
                            <div className="input-group mb-1">
                                <input type="text" className="form-control blog mr-2 text-right" placeholder='Due Amount' />
                                <input type="number" className="form-control text-center" value={Balence} />
                            </div>
                            <div className="input-group mt-5">
                                <input type="text" className="form-control blog mr-2" />
                                <button type="submit" className="btn btn-dark btn-sm mt-1 p-2" >Submit...</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    )
}

export default Invoicer;