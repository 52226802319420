// =========================================    {  Testimonials  }     ==========================
import axios from 'axios';
import React, { useState } from 'react'
import SidePanel from '../Admin-Dashbord/SidePanel';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import Dheader from '../Admin-Dashbord/header';
const Testimunial = () => {
    const [name, setname] = useState('');
    const [testimony, settestimony] = useState('');
    const [designation, setdesignation] = useState('');
    const [image, setimage] = useState('');
    const navigate = useNavigate();

    const FormData = {
        name, testimony, designation,
        image: 'image'
    }
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setimage(file);
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        //console.log(Form_Data);  
        try{
         const response=  axios.post('https://sdmipl.com/portal/api/testimonial-create', FormData)
         if (response.status === 200){
            navigate('/testimunial-view')
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: 'success',
              title: 'record has been updated ',
            });
          } else {
            navigate('/testimunial-view');
            alert("Internal server failed");
          }
        }catch(err){
            console.log(err)
            alert("Internal server failed");
            navigate('/testimunial-view');
        }
         
    }
    // logout Functionality
    const handleLogout = () => {
        const token = localStorage.getItem('token');
        const headers = {
            'Authorization': `Bearer ${token}`
        }
        if (token) {
            axios.post('https://www.sdmipl.com/portal/api/logout', { headers })
                .then((res) => {
                    alert(res.data.message);
                    localStorage.removeItem('token');
                    navigate('/login');
                })
                .catch((error) => {
                    console.error('Error logging out:', error);
                });
        } else {

            alert('No token found in local storage. User may not be logged in');
        }
    };

    return (
        <>
            <div className='d-flex'>
                <SidePanel />
                < section className="right-dash">
                 <Dheader />
                    <div className="container  mt-2 col-lg-12">
                        <div className='card create'>
                        <h2 className='text-center mt-2'> Testimonials Section</h2>
                        <div className="form-group">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="name"> Name : </label>
                                    <input type="text" onChange={(e) => setname(e.target.value)} className="form-control" id="name" name="name" placeholder="Enter your Name" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="testimony">Testimony</label>
                                    <textarea className="form-control" id="testimony" name="testimony" placeholder="Enter your Testimony" onChange={(e) => settestimony(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="designation"> Designation </label>
                                    <input type="text" className="form-control" onChange={(e) => setdesignation(e.target.value)} id="designation" name="designation" placeholder="Enter your Designation " />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="project-image">Post image:</label>
                                    <input type="file" className="form-control" id="project-image" name="image" onChange={handleImageChange} />
                                    {image && (
                                        <div>
                                            <p>Selected file: {image.name}</p>
                                            <img src={URL.createObjectURL(image)} alt="Selected-item" width="100" />
                                        </div>
                                    )}
                                </div>
                                <div className='btn'><input type="submit" className="btn btn-warning" value="Submit" /></div>
                            </form>
                        </div>
                        </div>
                    </div>
                </section>

            </div>
        </>

    )

}

export default Testimunial;