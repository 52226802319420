import React, { useState } from 'react'
import axios from 'axios';
import SidePanel from '../Admin-Dashbord/SidePanel';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import Dheader from '../Admin-Dashbord/header';
const Create_plan = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        title: '',
        package_description: '',
        service_charge: '',
        seo_title: '',
        seo_description: "",
        seo_keywords: '',
        canonical_link: ''
    });
    console.log(formData, 'formData');
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        //console.log(Form_Data);  
        try {
            const response = axios.post('https://sdmipl.com/portal/api/services-add', formData)
            if (response.status === 200) {
                navigate('/view_plans')
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 4000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    },
                });
                Toast.fire({
                    icon: 'success',
                    title: 'record has been updated ',
                });
            } else {
                navigate('/view_plans');
                alert("Internal server failed");
            }
        } catch (err) {
            console.log(err)
            alert("Internal server failed");
            navigate('/view_plans');
        }

    }
    // logout Functionality
    const handleLogout = () => {
        const token = localStorage.getItem('token');
        const headers = {
            'Authorization': `Bearer ${token}`
        }
        if (token) {
            axios.post('https://www.sdmipl.com/portal/api/logout', { headers })
                .then((res) => {
                    alert(res.data.message);
                    localStorage.removeItem('token');
                    navigate('/login');
                })
                .catch((error) => {
                    console.error('Error logging out:', error);
                });
        } else {

            alert('No token found in local storage. User may not be logged in');
        }
    };

    return (
        <>
            <div className='d-flex'>
                <SidePanel />
                < section className="right-dash">
                    <Dheader />
                    <div className="container  mt-3 col-lg-12">
                    <div className='card create'>
                        <h2 className='text-center'> Seo Plans </h2>
                        <div className="form-group">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group col-lg-6">
                                    <label htmlFor="name"> Title : </label>
                                    <input type="text" onChange={handleChange} className="form-control" id="title" name="title" placeholder="Enter title" />
                                </div>
                                <div className="form-group col-lg-6">
                                    <label htmlFor="name"> Seo Title : </label>
                                    <input type="text" onChange={handleChange} className="form-control" id="seo_title" name="seo_title" placeholder="Enter seo_title" />
                                </div>
                                <div className="form-group col-lg-6">
                                    <label htmlFor="name"> Seo keywords </label>
                                    <input type="text" onChange={handleChange} className="form-control" id="seo_keywords" name="seo_keywords" placeholder="Enter seo_keywords" />
                                </div>
                                <div className="form-group col-lg-6">
                                    <label htmlFor="seo_description">Seo description : </label>
                                    <input type="text" onChange={handleChange} className="form-control" id="seo_description" name="seo_description" placeholder="Enter seo_description" />
                                </div>
                                <div className="form-group col-lg-6">
                                    <label htmlFor="name"> Canonical link : </label>
                                    <input type="text" onChange={handleChange} className="form-control" id="canonical_link" name="canonical_link" placeholder="Enter canonical_link" />
                                </div>
                                <div className="form-group col-lg-6">
                                    <label htmlFor="name"> Service Charge : </label>
                                    <input type="text" onChange={handleChange} className="form-control" id="service_charge" name="service_charge" placeholder="Enter service_charge" />
                                </div>
                                <div className="form-group col-lg-12">
                                    <label htmlFor="name"> Package Description : </label>
                                    <textarea type="text" onChange={handleChange} className="form-control" id="package_description" name="package_description" placeholder="Enter package_description" />
                                </div>

                                <div className='btn'><input type="submit" className="btn btn-warning d-flex justify-content-end" value="Submit" /></div>
                            </form>
                        </div>
                    </div>
                    </div>
                </section>

            </div>
        </>

    )

}

export default Create_plan;
