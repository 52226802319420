import axios from 'axios'
import React from 'react'
import {  useNavigate } from 'react-router-dom';

const Centerpanel = () => {

    // logout and Centerpanel 
    const navigate = useNavigate();

    // logout Functionality
    const handleLogout = () => {
        const token = localStorage.getItem('token');
        if (token) {
            axios.post('https://www.sdmipl.com/portal/api/logout', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((res) => {
                    alert(res.data.message);
                    localStorage.removeItem('token');
                    navigate('/login');
                })
                .catch((error) => {
                    console.error('Error logging out:', error);
                });
        } else {

            alert('No token found in local storage. User may not be logged in');
        }
    };

    return (
        <>
            < section className="right-dash">
                <div id="right-panel" className="right-panel">
                    <header id="header" className="header">
                        <div className="header-menu">
                            <div className="col-sm-10">

                                <div className="page-header ">
                                    <div className="page-title">
                                        <h1>Sion Datamatics india Pvt Ltd</h1>
                                    </div>
                                </div>

                            </div>
                            <div className="col-sm-2 items-right  custom1">
                                <button className="nav-link cursor-pointer" onClick={handleLogout}><i className="fa fa-power-off text-danger" /> logout </button>
                                <div className="user-menu dropdown-menu">
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
            </section>

        </>

    )
}

export default Centerpanel
