import React, { useState, useEffect, useRef, } from 'react'
import JoditEditor from 'jodit-react';
import "./calculator.css"
import { Link } from 'react-router-dom';


const CM2 = () => {

    const editor = useRef(null);
    const [content, setContent] = useState('');
    console.log("ritchtextEditor", content)
    const [images, setImages] = useState([]);
    const [file, setFile] = useState(null);
    console.log(images, file);

    const handlegraphic = (event) => {
        setgraphiccategory(event.target.value);
    }

    // image upload multipal with preview start


    const handleImageChange = (e) => {
        const files = e.target.files;

        const totalSize = Array.from(files).reduce((acc, file) => acc + file.size, 0);
        const maxSize = 10 * 1024 * 1024; // 10 MB

        if (totalSize > maxSize) {
            alert('Total file size exceeds 10 MB. Please select fewer or smaller files.');
            return;
        }

        if (images.length + files.length > 3) {
            alert('You can upload a maximum of 3 images.');
            return;
        }

        const newImages = Array.from(files).map((file) => ({
            file,
            preview: URL.createObjectURL(file),
        }));

        setImages((prevImages) => [...prevImages, ...newImages]);
    };

    const handleRemoveImage = (index) => {
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
    };
    // upload pdf to backend 


    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];

        if (selectedFile && selectedFile.type === 'application/pdf') {
            // Read the file and convert it to a data URL
            const reader = new FileReader();
            reader.onloadend = () => {
                setFile(selectedFile);

            };
            reader.readAsDataURL(selectedFile);
        } else {
            // Handle invalid file type
            alert('Please select a valid PDF file.');
            setFile(null);
        }
    };


    // for Graphic Section

    const [Graphicpage, setGraphicpage] = useState(1)
    const [graphicrate, setGraphicrate] = useState(1)
    const [GraphicTax, setGraphicTax] = useState(18)
    const [graphiccategory, setgraphiccategory] = useState(0)
    const [graphiccount, setgraphiccount] = useState(0.00)

    useEffect(() => {
        const graphicdata = parseInt(graphiccategory) * parseInt(Graphicpage);
        setGraphicrate(graphicdata);
        const gtax = (parseInt(graphicdata) * 18) / 100;
        setGraphicTax(gtax);
        const add = parseInt(graphicdata) + parseInt(gtax);
        setgraphiccount(add)
    }, [Graphicpage, graphiccategory])
    // End Graphics section




    return (
        <>
            <section className="main-heading">
                <div className="container-fluid">
                    <div className="Header-Links ">
                        <ul className='d-flex justify-content-between'>
                            <li><Link to="/website" >Website Budget Calculator</Link> </li>
                            <li><Link to="/website/graphics" >Graphic Budget Calculator</Link> </li>
                            <li><Link to="/website/graphics/editing" >Editing Budget Calculator</Link> </li>
                            <li><Link to="/website/graphics/editing/social" >Social Media Budget Calculator</Link> </li>
                        </ul>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="text-center mt-5">Graphics Budget Calculator</h1>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container-fluid budget pb-5">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="services mt-5 ">
                            <div className="one p-3">
                                <div className="row ">
                                    <div className="col-lg-6 col pl-0 p-2">

                                        <div className="dropd">
                                            <div className="form-check  ">
                                                <input className="form-check-input mt-2" type="radio" name="group0"
                                                    id="exampleRadios1" value={1000} onChange={handlegraphic} />
                                                <label className="form-check-label" htmlFor="exampleRadios1">
                                                    <span className="hh6 text-center"> Logo Designing</span>
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input mt-2" type="radio" name="group0"
                                                    id="exampleRadios1" value={2000} onChange={handlegraphic} />
                                                <label className="form-check-label" htmlFor="exampleRadios1">
                                                    <span className="hh66"> Website Designing </span>
                                                </label>

                                            </div>
                                            <div className="form-check  ">
                                                <input className="form-check-input mt-2" type="radio" name="group0"
                                                    id="exampleRadios1" value={400} onChange={handlegraphic} />
                                                <label className="form-check-label" htmlFor="exampleRadios1">
                                                    <span className="hh6 text-center"> Motion Graphics </span>
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input mt-2" type="radio" name="group0"
                                                    id="exampleRadios1" value={750} onChange={handlegraphic} />
                                                <label className="form-check-label" htmlFor="exampleRadios1">
                                                    <span className="hh66"> Normal Graphics </span>
                                                </label>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col d-flex justify-content-center align-items-center ">
                                        <h6 className="special pr-2 hh6 ">Select Quantity </h6>
                                        <div className="form-group">
                                            <input type="Number" className="form-control input_num mb-0 text-center input-sm"
                                                id="exampleInputPassword1" value={Graphicpage} onChange={(e) =>
                                                    setGraphicpage(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="services mt-5">
                            <div class="one">
                                <div class="row ">
                                    <h2 class="mt-5 text-center">Documentation</h2>

                                    <div class="col-lg-6  col-sm-6 mb-5 px-5">


                                        <div className="form-group mt-5">
                                            <label htmlFor="exampleFormControlFile1" className="doc mb-5">
                                                Upload your logo(naximum 3 Images)
                                            </label>
                                            <input
                                                type="file"
                                                className="form-control-file"
                                                id="exampleFormControlFile1"
                                                accept="png, jpeg, jpg"
                                                onChange={handleImageChange}
                                                multiple
                                            />
                                        </div>
                                        <div className="image-row">
                                            {images.map((image, index) => (
                                                <div key={index} className="image-preview-container">
                                                    <img src={image.preview} alt={`Preview ${index}`} className="image-preview" />
                                                    <span onClick={() => handleRemoveImage(index)} className="fa fa-trash text-danger ml-1">
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-sm-6 mb-5 px-5">
                                        <div className="form-group mt-5">
                                            <label htmlFor="exampleFormControlFile1" className="doc mb-5">
                                                Upload your Document
                                            </label>
                                            <input
                                                type="file"
                                                className="form-control-file"
                                                id="exampleFormControlFile1"
                                                accept="application/pdf"
                                                onChange={handleFileChange}
                                            />
                                        </div>


                                    </div>
                                    {/*   text editor */}
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <JoditEditor
                                                    ref={editor}
                                                    value={content}
                                                    //config={config}
                                                    tabIndex={1} // tabIndex of textarea
                                                    onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                                    onChange={newContent => setContent(newContent)}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-4">
                        <div className="second-side mt-5 ">
                            <div className="inner">
                                <h4 className="head "> Order Summary</h4>
                                <div className="total">
                                    <div className="inr-total d-flex justify-content-space-around my-auto">
                                        <h6>Total Amount</h6>
                                        <p>
                                            <span className='mr-2' id="pages">{graphiccategory}</span>
                                            <i className="fa-solid fa-xmark text-dark" />
                                            <span className='ml-3' id="fix">{Graphicpage}</span>
                                        </p>
                                        <p>
                                            <i className="fa-solid fa-indian-rupee-sign" />
                                            <span id="result">{graphicrate}</span>
                                        </p>
                                    </div>

                                    <div className=" inr-total  d-flex justify-content-space-around my-auto">
                                        <span ><h6 className='d-flex'>Taxes <span> (18%) </span></h6></span>
                                        <p>
                                            <i className="fa-solid fa-indian-rupee-sign" />
                                            <span id="tax">{GraphicTax}</span>
                                        </p>
                                    </div>
                                    <div className=" inr-total grand d-flex justify-content-space-around my-auto">
                                        <h5>Grand Total</h5>
                                        <p>
                                            <i className="fa-solid fa-indian-rupee-sign" />
                                            <span id="gt">{graphiccount}</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="check">
                                    <div className="input-group mb-3">
                                        <div className="form-check">

                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="btn btnn" id="submit">
                                    Submit
                                </button>
                                <button type="button" className="btn btn-primary">
                                    <Link to="https://www.sdmipl.com/" className="text-light" style={{ textDecoration: 'none' }}>Go to the HomePage</Link>
                                </button>
                            </div>
                        </div>
                    </div>


                </div>
            </div>



        </>
    )
}

export default CM2






