import React, { useState } from 'react';
import axios from 'axios';
import SidePanel from '../Admin-Dashbord/SidePanel';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import Dheader from '../Admin-Dashbord/header';

const OurTeam = () => {
    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);
    const [description, setDescription] = useState('');

    const FormData = {
        title: title,
        description: description,
        image: image
    }
    const navigate = useNavigate();

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log(FormData);
            const response = await axios.post('#', FormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },

            });
            //  console.log(response)
            if (response.status === 200) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 4000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    },
                });
                Toast.fire({
                    icon: 'success',
                    title: 'Data inserted Successfully',
                });
                navigate('/ourTeam_view');
            } else {
                alert("Internal server failed");
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert("Error submitting form. Please try again.");
        }
    };

    return (
        <>
            <div className='d-flex'>
                <SidePanel />
                <section className="right-dash">
                    <Dheader />
                    <div className="container mt-2 ">
                        <div className='row col-lg-12'>
                            <div className='card create'>
                                <h2 className='text-center'>Our Team</h2>
                                <div className="form-group">
                                    <form onSubmit={handleSubmit} encType='multipart/form-data'>
                                        <div className="form-group">
                                            <label htmlFor="Employee Name">Employee Name:</label>
                                            <input type="text" onChange={(e) => setTitle(e.target.value)} className="form-control" id="Employee Name" name="Employee Name" placeholder="Enter your Project Title" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="EmpDesignation">Designation</label>
                                            <textarea className="form-control" onChange={(e) => setDescription(e.target.value)} id="project-description" name="EmpDesignation" placeholder="Enter Employee Designation" />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="job-image"> image:</label>
                                            <input type="file" className="form-control" id="job-image" name="image" onChange={handleImageChange} />
                                            {image && (
                                                <div>
                                                    <p>Selected file: {image.name}</p>
                                                    <img src={URL.createObjectURL(image)} alt="Selected-item" width="100" />
                                                </div>
                                            )}
                                        </div>
                                        <div className='btn'><input type="submit" className="btn btn-warning" value="Submit" /></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default OurTeam;
