import React, { useState } from 'react';
import SidePanel from '../Admin-Dashbord/SidePanel';
import Dheader from '../Admin-Dashbord/header';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function Create_service() {
    const navigate = useNavigate();
    const [image, setImage] = useState(null);
    const [formData, setFormData] = useState({
        service_card_title: '',
        service_card_description: '',
        service_card_image: null 
    });
    console.log(formData, 'formData');
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            setImage(file);
            setFormData({
                ...formData,
                service_card_image: file
            });
        } else {
            setImage(null);
            setFormData({
                ...formData,
                service_card_image: null 
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('service_card_title', formData.service_card_title);
            formDataToSend.append('service_card_description', formData.service_card_description);
            formDataToSend.append('service_card_image', formData.service_card_image);
            console.log('Form Data to Send:', formDataToSend);
            const response = await axios.post('https://www.sdmipl.com/portal/api/add-service-card', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 4000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    },
                });
                Toast.fire({
                    icon: 'success',
                    title: 'Data inserted Successfully',
                });
                navigate('/view_services');
            } else {
                console("Internal server failed");
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert("Error submitting form. Please try again.");
        }
    };

    return (
        <div className='d-flex'>
            <SidePanel />
            <section className="right-dash">
                <Dheader />
                <div className="container">
                    <div className="row">
                        <div className="card create">
                            <h2 className='text-center'> Our Services </h2>
                            <div className="form-group">
                                <form onSubmit={handleSubmit} encType='multipart/form-data'>
                                    <div className="form-group">
                                        <label htmlFor="Service Title">Service Title:</label>
                                        <input type="text" onChange={handleChange} className="form-control" id="Service Title" name="service_card_title" placeholder="Enter your Service Title" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Description">Description</label>
                                        <textarea className="form-control" onChange={handleChange} id="service-description" name="service_card_description" placeholder="Enter Service Description" />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="Service-image">Image:</label>
                                        <input type="file" className="form-control" id="Service-image" name="service_card_image" onChange={handleImageChange} />
                                        {image && (
                                            <div>
                                                <p>Selected file: {image.name}</p>
                                                <img src={URL.createObjectURL(image)} alt="Selected-item" width="100" />
                                            </div>
                                        )}
                                    </div>
                                    <div className='btn'><input type="submit" className="btn btn-warning" value="Submit" /></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Create_service;
